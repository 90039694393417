import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { EmailActionManagerComponent } from './email-action-manager/email-action-manager.component';
import { MondoRoutes, RoutingModel } from './app.routing-model';
import { AppCustomPreloader } from './app.custom-preloader';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: RoutingModel.login.path,
    data: { animation: 'root' },
  },
  {
    path: 'linkedin-popup',
    loadChildren: './linkedin-popup/linkedin-popup.module#LinkedinPopupModule',
  },
  {
    path: 'login',
    loadChildren: './login/login.module#LoginModule',
  },
  {
    path: 'signup',
    loadChildren: './signup/signup.module#SignupModule',
  },
  {
    path: 'email-action-manager',
    component: EmailActionManagerComponent,
    data: { animation: 'email-action-manager' },
  },
  {
    path: MondoRoutes.landingPage,
    loadChildren:
      './landing-page-wrapper/landing-page-wrapper.module#LandingPageWrapperModule',
  },
  {
    path: RoutingModel.home.path,
    loadChildren: './home/home.module#HomeModule',
  },
  {
    path: 'job',
    loadChildren: './job-linker/job-linker.module#JobLinkerModule',
  },
  {
    path: RoutingModel.jobBuilder.path,
    loadChildren: './stepper/job/job.module#JobModule',
  },
  {
    path: 'link',
    loadChildren: './profile/profile.module#ProfileModule',
    data: { preload: true },
  },
  {
    path: RoutingModel.profileEditor.path,
    loadChildren: './profile-editor/profile-editor.module#ProfileEditorModule',
    data: { preload: true },
  },
  {
    path: RoutingModel.adminPage.path,
    loadChildren: './admin-page/admin-page.module#AdminPageModule',
    data: { preload: false },
  },
  {
    path: RoutingModel.dashboard.path,
    loadChildren: './dashboard/dashboard.module#DashboardModule',
    data: { preload: true },
  },
  {
    path: RoutingModel.events.path,
    loadChildren: './groups/events/events.module#EventsModule',
    data: { preload: true },
  },
  {
    path: RoutingModel.communities.path,
    loadChildren: './groups/communities/communities.module#CommunitiesModule',
    data: { preload: true },
  },
  {
    path: RoutingModel.ideas.path,
    loadChildren: './groups/project-idea/ideas.module#IdeasModule',
    data: { preload: true },
  },
  {
    path: 'about',
    loadChildren: './about-us/about-us.module#AboutUsModule',
  },
  {
    path: RoutingModel.map.path,
    loadChildren: './map/map.module#MapModule',
    data: { preload: true },
  },
  {
    path: RoutingModel.scienceMatch.path,
    loadChildren: './science-match/science-match.module#ScienceMatchModule',
    data: { preload: true },
  },
  {
    path: RoutingModel.chat.path,
    loadChildren: './chat/chat.module#ChatModule',
    data: { preload: true },
  },
  {
    path: RoutingModel.playground.path,
    loadChildren: './playground/playground.module#PlaygroundModule',
    data: { preload: false },
  },
  {
    path: RoutingModel.wp.path,
    loadChildren: './wordpress/wordpress.module#WordpressModule',
    data: { preload: false },
  },
  {
    path: 'new-user',
    loadChildren: './new-user/new-user.module#NewUserModule',
  },
  {
    path: RoutingModel.cvBuilder.path,
    loadChildren: './stepper/cv/cv.module#CVModule',
  },
  {
    path: RoutingModel.cookies.path,
    loadChildren: './cookie/cookie.module#CookieModule',
    data: { preload: false },
  },
  {
    path: RoutingModel.gdpr.path,
    loadChildren:
      './privacy-and-terms/privacy-and-terms.module#PrivacyAndTermsModule',
    data: { preload: false },
  },
  {
    path: RoutingModel.ai.path,
    loadChildren: './ai/ai.module#AIModule',
    data: { preload: false },
  },
  {
    path: '**',
    pathMatch: 'full',
    redirectTo: RoutingModel.login.path,
    data: { animation: '**' },
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      onSameUrlNavigation: 'reload',
      preloadingStrategy: AppCustomPreloader,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
