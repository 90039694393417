import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TourGuideComponent } from './tour-guide/tour-guide.component';
import { AngularMaterialSharedModule } from 'app/angular-material-shared/angular-material-shared.module';

@NgModule({
  declarations: [TourGuideComponent],
  imports: [CommonModule, AngularMaterialSharedModule],
  exports: [TourGuideComponent],
})
export class TourGuideModule {}
