import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { AuthService } from 'app/core/auth.service';
import { AccountType } from 'app/shared/consts/accountType';
import { MondoUser } from 'app/shared/models/user/mondoUser';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-sso-user-mark',
  templateUrl: './sso-user-mark.component.html',
  styleUrls: ['./sso-user-mark.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SsoUserMarkComponent implements OnInit {
  @Input() userType: AccountType;
  @Input() userId: string;
  AccountTypes = AccountType;

  user$: Observable<MondoUser>;
  constructor(private authService: AuthService) {}

  ngOnInit() {
    if (this.userId) {
      this.user$ = this.authService.readUserByUid(this.userId);
    }
  }
}
