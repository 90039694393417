import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { MatDialog } from '@angular/material';
import { getFiltersFromExperience, getFiltersFromGroup } from '@helpers';
import { RoutingModel } from 'app/app.routing-model';
import { AuthService } from 'app/core/auth.service';
import { RoutingService } from 'app/core/routing.service';
import { SiteService } from 'app/core/site.service';
import { Idea } from 'app/groups/project-idea/models/idea';
import { IdeasService } from 'app/groups/project-idea/services/ideas.service';
import { GroupService } from 'app/groups/services/group.service';
import { IFilter, Site, Subtypes, UserFilter } from 'app/shared/models';
import { Observable } from 'rxjs';
import { tap, throttleTime } from 'rxjs/operators';
import { AcaCarouselComponent } from '../aca-carousel/aca-carousel.component';

@Component({
  selector: 'app-idea-viewer',
  templateUrl: './idea-viewer.component.html',
  styleUrls: ['./idea-viewer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IdeaViewerComponent implements OnChanges {
  @Input() idea: Idea;
  @Input() showVisit = false;
  @Input() showCoverImage = true;
  site$: Observable<Site>;
  site: Site;

  constructor(
    private routingService: RoutingService,
    private dialog: MatDialog,
    private siteService: SiteService,
    private authService: AuthService,
    private ideasService: IdeasService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    const idea = changes.idea;
    if (!idea.firstChange && idea.currentValue && !idea.previousValue) {
      this.site$ = this.siteService
        .getPublishedSite(idea.currentValue.siteKey)
        .pipe(
          throttleTime(5000),
          tap((site) => {
            this.site = site;
          }),
          tap((site) => {
            if (site) {
              this.idea.siteDescription = site.siteInfo.info;
              this.idea.siteStatus = site.siteInfo.siteStatus;
              this.idea.tags.category0 = site.experience.category0;
              this.idea.tags.category2 = site.experience.category2;
              this.idea.tags.subtypes = site.siteInfo.subtypes;
              return this.ideasService.updateDraftIdea(this.idea);
            }
          })
        );
    }
    if (!idea.firstChange && idea.currentValue !== idea.previousValue) {
      this.idea = idea.currentValue;
    }
  }

  get isAdmin() {
    return this.authService.isAdmin;
  }

  get filters(): IFilter[] {
    return getFiltersFromGroup(this.idea);
  }

  get siteFilters(): IFilter[] {
    const tags = getFiltersFromExperience(this.site.experience);
    let subtypes = [];
    if (this.site.siteInfo.subtypes) {
      subtypes = this.site.siteInfo.subtypes.map((i: Subtypes) => {
        return new UserFilter(i.id, i.name, 'siteSubtype');
      });
    }
    return [...tags, ...subtypes];
  }

  openImages(idea: Idea) {
    const slideShow = [];
    idea.uploads.forEach((upload) => {
      slideShow.push({ img: upload.url });
    });
    const config = {
      panelClass: 'job-carousel-dialog',

      data: { slides: slideShow },
    };

    this.dialog.open(AcaCarouselComponent, config);
  }

  gotoIdea(key: string) {
    this.routingService.navigateToRoute(RoutingModel.ideas.path, [
      key,
      'about',
    ]);
  }

  get ideaAddress(): string {
    return GroupService.getGroupAddress(this.idea);
  }

  get addressSite() {
    if (
      this.site.siteInfo.addressSite &&
      this.site.siteInfo.addressSite.formattedAddress
    ) {
      return `${this.site.siteInfo.addressSite.formattedAddress}`;
    }
    if (
      this.site.siteInfo.addressSite &&
      this.site.siteInfo.addressSite.city &&
      this.site.siteInfo.addressSite.country &&
      this.site.siteInfo.addressSite.country.name
    ) {
      return `${this.site.siteInfo.addressSite.city}, ${this.site.siteInfo.addressSite.country.name}`;
    }
    return this.site.siteInfo.addressSite.city;
  }

  trackByFn(index, item) {
    return item.key;
  }
}
