import { AccountPermission } from './app/shared/consts/accountPermission';
import { MailFrequency } from './app/notifications-new/models/mail-frequency';

export const configSUSTAINABLE = {
  academondo: 'Greenlink',
  academondoDash: 'Greenlink',
  academondoCom: 'app.greenlink.dk',
  academondoUrl: 'https://app.greenlink.dk',
  supportMail: 'gogreen@greenlink.dk',
  reviewMail: 'gogreen@greenlink.dk',
  senderMail: 'gogreen@greenlink.dk',
  welcomeScientistTemplateId: 5,
  welcomeCompanyTemplateId: 5,
  inviteTemplateId: 6,

  notificationTemplateId: 7,

  defaultNotificationFreq: MailFrequency.Asap,
  dateFormat: 'da-DK',
  languageCKEditor: 'da-DK',

  language: 'da',
  defaultUserCoverUrl:
    'https://strapi-prod.sos-ch-dk-2.exo.io/ebrains_ssd_call_ebrains_website_3a68136980.jpg',
  defaultEventCoverUrlCredit: '',
  defaultEventCoverUrl:
    'https://firebasestorage.googleapis.com/v0/b/academondo-demo.appspot.com/o/00000xxx%2Fevent-cover.jpg?alt=media&token=cbbfaadc-e88c-4e86-9a3e-47ef36c97fdd',
  defaultCommunityCoverUrlCredit: '',
  defaultCommunityCoverUrl:
    'https://firebasestorage.googleapis.com/v0/b/academondo-demo.appspot.com/o/00000xxx%2Fcommunity-cover.jpg?alt=media&token=9cff048f-c0ce-4363-97b7-61f43fa1051a',
  defaultIdeaCoverUrlCredit: '',
  defaultIdeaCoverUrl:
    'https://firebasestorage.googleapis.com/v0/b/academondo-demo.appspot.com/o/00000xxx%2Fidea-cover.png?alt=media&token=953c4f68-9802-4cf6-994f-94c3876edd9f',
  welcomeWidgetCoverUrlCredit: '',
  welcomeWidgetCoverUrl: '',

  welcomeWidgetLogo: false,
  showWelcomeWidget: true,

  termsLink: 'https://greenlink.dk/consent-form/',
  privacyPolicyLink: 'https://greenlink.dk/privacypolicy/',
  copyRightDate: '© 2022,',

  academondoStreet: 'Holbergsvej 22,',
  academondoPostboxCity: '8800 Viborg',
  academondoCVR: '41632437',
  academondoPhone: '+45 21 42 64 44',

  twitterLink: '', // leave blank if it should not be shown
  facebookLink: '', // leave blank if it should not be shown
  linkedinLink: '', // leave blank if it should not be shown
  instagramLink: '', // https://www.instagram.com/companyName/ // leave blank if it should not be shown
  fbPagePluginLink: '',

  showTermsInNavbarMenu: true,

  showSiteTagsAsChips: true,
  showCvTagsAsChips: true,

  showSitesAlphabetically: false,
  showMaxSearchResultSelector: false,

  maxDistanceInSearch: 350,
  maxSearchResults: 350,
  showProfileWidgetStats: false,
  showSearchForXButtonInProfileWidget: 'Job', // SearchableTypes.Job,
  showSearchForXButtonInProfileWidgetText: 'Find Børsopslag',
  showCreateButtonCommunityInProfileWidget: false,
  showCreateButtonEventInProfileWidget: false,
  showCreateButtonIdeaInProfileWidget: false,
  showDateOnPostsInFeedWidget: false,
  showMemberCountOnEventWidget: true,

  urlForWordpressnews: null, //'https://sustainreport.dk/',
  TitleForWordpressnews: 'Sustain report',
  WordpressnewsFilter: null,
  urlForWordpressnews2: null, //'https://impactinsider.dk',
  TitleForWordpressnews2: 'Impact insider',
  Wordpressnews2Filter: null,
  EnableMultiwordpressFeeds: true,
  TitleForMultiwordpressFeeds: 'Esport Danmark',
  MultiwordpressFeeds: ['https://esd.dk'],
  MultiwordpressFeedsFilter: null,
  urlForWordpresstools: null, //'https://partnerskaberne.dk',
  TitleForWordpresstools: 'Værktøjer',
  WordpresstoolsFilter: 47,

  EnablePremiumCommunities: false,

  umamiKey: '9cea10f9-4449-448d-be51-966ddf7e093d',

  //suggestion
  enableCategory0Suggestions: true,

  // matchMyFilter
  SkipInterests: false,
  SkipTechniques: false,
  SkipCat0: false,
  SkipCat1: false,
  SkipCat2: false,
  SkipCat3: false,
  SkipCat4: false,
  SkipCat5: false,
  SkipSiteSubtype: false,
  SkipNetworkSubtype: false,
  ExternalLinkedinFeed: '67265', // remember to set Id and link in dashboard.ts
  TwitterListHref: false,
  ExternalFeedLeft: false,
  ExternalFeedRight: true,

  SSOEnabled: false,
  LinkedinEnabled: true,
  LinkedinClientId: '865gve14kylnmm',
  enabledSubgroups: true,

  enabledSubgroupsInIdeas: false,
  groupSideBarExpanded: true,

  onlyCreateIdeasFromSite: false,

  expireSites: false,
  expireProfiles: false,
  startingPermissionLevel: AccountPermission.basic,
  priceOnSite: false,
  specialUserAccountTypes: [
    // AccountType.scientist,
    // AccountType.labTech,
    // AccountType.medLabTech,
    // AccountType.industry,
    // AccountType.university,
  ],

  enableEventsForUnAuthedUsers: true,
  enableCommunitiesForUnAuthedUsers: true,
  enableIdeasForUnAuthedUsers: true,

  showPrintSiteButton: false,
  showPrintableCVButton: false,
  enableEvents: true,
  enableCommunities: true,
  enableChat: true,
  groupChatEnabled: false,
  groupKanbanEnabled: false,

  canHideIdeaFromSearch: true,
  hideMentorShipOnNewMember: false,

  copyEvent: true,
  copyCommunity: true,
  copyIdea: true,
  showGroupOnGroupMap: false,
  ideaWantedExperience: false,
  ideaImages: false,

  jobWantedExperience: true,
  jobImages: 'Billeder',
  enableJobs: false,
  fillCompanyInfoFromProfileInJobPost: true,
  jobResearchQualificationsAsFilters: false,
  showMiddleName: false,

  allowMultipleItems: false,

  slimSiteIndex: false,
  siteIsUserDriven: true,
  showDepartmentInPersonalDetails: false,
  showSexInPersonalDetails: false,

  showCreateCommunityButtonForUserWithoutPermission: true,
  showCreateEventButtonForUserWithoutPermission: true,
  showCreateIdeaButtonForUserWithoutPermission: true,

  enableMagicLink: false,
  showCVRInputInSiteInfo: false,
  showSiteInfoStep: false,

  enableCoupling: false,
  showApplicationProcessFunctions: false, // leave blank if it should not be shown

  registerVisitFunctions: '', // leave blank if it should not be shown

  showRelatedJobsFunctions: true, // leave blank if it should not be shown

  showCalendarInNetworks: false, // leave as false if it should not be shown'

  showBorderedButtonsInNavBar: false, // leave as false if it should not be shown
  showCapitalizedButtonsInNavBar: false, // leave as false if it should not be shown
  showNavSpacersInNavBar: false, // leave as false if it should not be shown

  showAccountChangerInNavBar: false, // leave as false if it should not be shown
  showAccountSelectorIcons: false, // leave as false if it should not be shown
  showChangeAccountTypeInSignup: true, // leave as false if it should not be shown

  enableTagUsersInComments: true, // leave as false if it should not be shown
  enableTagUsersInPosts: true, // leave as false if it should not be shown

  showSiteImageOnMap: true, // leave as false if it should not be shown
  showPlaceImageOnMap: false, // leave as false if it should not be shown
  showNetworkImageOnMap: false, // leave as false if it should not be shown
  showCommunityImageOnMap: false, // leave as false if it should not be shown
  showIdeaImageOnMap: false, // leave as false if it should not be shown
  showEventImageOnMap: false, // leave as false if it should not be shown
  showCreatorOfCommunities: true, // leave as false if it should not be shown
  showCreatorOfEvents: true, // leave as false if it should not be shown
  showCreatorOfIdeas: true, // leave as false if it should not be shown
  showOptionToRemoveUsersPostsAndEvEventsOnLeaving: false, // leave as false if it should not be shown
  showOptionToRemoveUsersPostsAndEvEventsOnRemovingUser: false, // leave as false if it should not be shown
  showInviteWidget: true, // leave as false if it should not be shown
  showBasicStep: false, // leave as false if it should not be shown
  RegisterDirectMatch: '', // 'Registrer direkte match', // leave blank if it should not be shown
  contactConsentGiven: '', // 'indkøbsservice', // leave blank if it should not be shown

  u0Marker: '/assets/icons/dc3545-marker.png',
  siteMarker: '/assets/icons/008bff-marker.png',
  u2Marker: '/assets/icons/008bff-marker.png',
  u3Marker: '/assets/icons/fd7e14-marker.png',
  u4Marker: '/assets/icons/6f42c1-marker.png',
  u5Marker: '/assets/icons/40722c-marker.png',
  gMarker: '/assets/icons/6f42c1-marker.png',
  cMarker: '/assets/icons/007816-marker.png',
  eMarker: '/assets/icons/ffc107-marker.png',
  iMarker: '/assets/icons/6c757d-marker.png',
  nMarker: '/assets/icons/254e58-marker.png',
  p0Marker: '/assets/icons/ffc107-marker.png',
  p1Marker: '/assets/icons/e83e8c-marker.png',
  p2Marker: '/assets/icons/6f42c1-marker.png',
  p3Marker: '/assets/icons/6c757d-marker.png',
};
