import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { RoutingModel } from 'app/app.routing-model';
import { AuthService } from 'app/core/auth.service';
import { RoutingService } from 'app/core/routing.service';
import { ForumService } from 'app/forum/services/forum.service';
import { DAO } from 'app/shared-services/db-access/dao';
import { MembershipService } from 'app/shared-services/membership/membership.service';
import { isNil, isObject } from 'app/shared/common/acaLodash';
import { DataConstants } from 'app/shared/consts/dataConstants';
import { Upload } from 'app/shared/models';
import { combineLatest, Observable } from 'rxjs';
import { first, map, mergeMap } from 'rxjs/operators';
import { Community, ICommunity } from '../communities/models/community';
import { Event, IEvent } from '../events/models/event';
import { Group, IGroup } from '../models/group';
import { GroupRelation } from '../models/group-relation';
import { GroupType } from '../models/group-type';
import { Idea, IIdea } from '../project-idea/models/idea';

export interface GroupInfo {
  key: string;
  type: GroupType;
}

@Injectable({
  providedIn: 'root',
})
export class GroupService {
  userId: string;

  static getGroupAddress(entity: Idea | Event | Community): string {
    let shortAddress = '';
    if (
      entity &&
      entity.location &&
      (entity.location.city ||
        (entity.location.country && entity.location.country.name))
    ) {
      shortAddress = entity.location.city.concat(
        ' ',
        entity.location.country.name
      );
    }
    return shortAddress;
  }

  constructor(
    private dao: DAO,
    private authService: AuthService,
    private membershipService: MembershipService,
    private forumService: ForumService,
    private routingService: RoutingService
  ) {
    this.authService.getCurrentUser$().subscribe((user) => {
      if (user) {
        this.userId = user.uid;
      } else {
        this.userId = undefined;
      }
    });
  }

  getGroupFromRoute(route: ActivatedRoute): Observable<Group> {
    return this.getGroupInfoFromRoute(route).pipe(
      mergeMap((info) => {
        return this.getGroup$(info.key, info.type);
      })
    );
  }

  getGroupRelationFromRoute(route: ActivatedRoute): Observable<GroupRelation> {
    return this.getGroupInfoFromRoute(route).pipe(
      mergeMap((info) => {
        return this.getgroupRelation$(info.key, info.type);
      })
    );
  }

  getGroupInfoFromRoute(route: ActivatedRoute): Observable<GroupInfo> {
    return route.data.pipe(
      mergeMap((data) => {
        return route.parent.params.pipe(
          map((params) => {
            return { key: params.groupKey, type: data.groupType };
          })
        );
      })
    );
  }

  public getGroups$(
    relation: GroupRelation,
    groupType: GroupType
  ): Observable<Array<Group>> {
    return this.getGroupIds(groupType, relation).pipe(
      mergeMap((ids) =>
        ids.length > 0
          ? combineLatest(ids.map((c) => this.getGroup$(c, groupType)))
          : []
      )
    );
  }

  public getGroup$(
    key: string,
    groupType: GroupType,
    relation?: GroupRelation
  ): Observable<Group | undefined> {
    if (relation === undefined) {
      return this.getgroupRelation$(key, groupType).pipe(
        mergeMap((userRelation) =>
          this.dao
            .object$<IGroup<number>>(
              this.getGroupPath(userRelation, key, groupType)
            )
            .pipe(
              map((com) => (isObject(com) ? this.fromJson(com) : undefined))
            )
        )
      );
    } else {
      return this.dao
        .object$<ICommunity<number>>(this.getGroupPath(relation, key))
        .pipe(map((com) => (isObject(com) ? this.fromJson(com) : undefined)));
    }
  }

  getAllUploadedFilesInForum(forumId: string) {
    return this.dao
      .list(DataConstants.FORUM_FILES + forumId)
      .snapshotChanges()
      .pipe(
        map((snaps) => {
          const uploadedFiles: Upload[] = [];
          snaps.map((snap) => {
            const postKeys = snap.payload.val();
            if (postKeys) {
              (postKeys as Array<any>).map((file) => {
                // console.log(file);
                // console.log(file && file.name);
                uploadedFiles.push(file);
              });
            }
          });
          return uploadedFiles;
        })
      );
  }

  getGroupsByType(type: GroupType): Observable<Group[]> {
    return this.dao
      .list(DataConstants.PUBLISHED_GROUPS, (ref) =>
        ref.orderByChild('groupType').equalTo(type)
      )
      .snapshotChanges()
      .pipe(
        map((snaps) => {
          return snaps.map((s) => {
            const group = s.payload.val();
            // console.log(group);
            // group['uid'] = s.payload.key;
            return group as Group;
          });
        })
      );
  }

  getGroups(): Observable<Group[]> {
    return this.dao
      .list(DataConstants.PUBLISHED_GROUPS)
      .snapshotChanges()
      .pipe(
        map((snaps) => {
          return snaps.map((s) => {
            const group = s.payload.val();
            // console.log(group);
            // group['uid'] = s.payload.key;
            return group as Group;
          });
        })
      );
  }

  public getGroup(
    key: string,
    groupType: GroupType,
    relation?: GroupRelation
  ): Promise<Group> {
    return this.getGroup$(key, groupType, relation).pipe(first()).toPromise();
  }

  public getGroupName$(key: string, groupType: GroupType): Observable<String> {
    return this.getGroup$(key, groupType).pipe(
      map((group) => (group ? group.name : ''))
    );
  }

  public getgroupRelation$(
    key: string,
    groupType: GroupType
  ): Observable<GroupRelation> {
    return this.membershipService.getRelation$(key, groupType);
  }

  public getGroupRelationWithUserId$(
    key: string,
    groupType: GroupType,
    userId: string
  ): Observable<GroupRelation> {
    return this.membershipService.getRelationWithUserId$(
      key,
      groupType,
      userId
    );
  }

  public async hasGroupBeenPublished(
    key: string,
    groupType: GroupType
  ): Promise<boolean> {
    const relation = await this.getgroupRelation$(key, groupType)
      .pipe(first())
      .toPromise();
    if (
      relation === GroupRelation.Owner ||
      relation === GroupRelation.Administrator ||
      relation === GroupRelation.Drafting
    ) {
      const draft = await this.getGroup(key, groupType, GroupRelation.Drafting);
      const published = await this.getGroup(
        key,
        groupType,
        GroupRelation.Owner
      );

      if (draft && isNil(published)) {
        return false;
      }
    }
    return true;
  }

  public async isDraftPublished(
    key: string,
    groupType: GroupType
  ): Promise<boolean> {
    const relation = await this.getgroupRelation$(key, groupType)
      .pipe(first())
      .toPromise();
    if (
      relation === GroupRelation.Owner ||
      relation === GroupRelation.Administrator ||
      relation === GroupRelation.Drafting
    ) {
      const draft = await this.getGroup(key, groupType, GroupRelation.Drafting);
      const published = await this.getGroup(
        key,
        groupType,
        GroupRelation.Owner
      );

      if (draft && isNil(published)) {
        return false;
      }

      if (draft && draft.lastUpdate && published && published.lastUpdate) {
        return draft.lastUpdate.getTime() === published.lastUpdate.getTime();
      }
      return false;
    }
    return true;
  }

  public updateDraftGroup(group: Group): Promise<void> {
    group.lastUpdate = new Date();
    return this.dao
      .object(DataConstants.DRAFT_GROUPS + group.key)
      .update(this.toJson(group));
  }

  async publishGroup(key: string, groupType: GroupType): Promise<void> {
    try {
      const group = await this.getGroup(key, groupType, GroupRelation.Drafting);
      let forum;
      forum = await this.forumService.getForum(group.forumId);

      if (!forum) {
        forum = await this.forumService.createForum(true, true);
        if (group.forumId) {
          forum.id = group.forumId;
        }
        forum.ownerId = group.ownerId;
      }

      group.permissions.onlyOwnerCanComment = false;
      forum.onlyOwnerCanCreateComments = false;
      forum.onlyOwnerCanCreatePosts = group.permissions.onlyOwnerCanCreatePosts;

      group.publicityDate = new Date();
      await this.forumService.updateForum(forum);
      await this.membershipService.makeOwner(key, groupType);
      await this.dao
        .ref(DataConstants.PUBLISHED_GROUPS + group.key)
        .update(this.toJson(group))
        .then(() => {
          this.authService.notEnoughPermission('published', 5000);
        });
    } catch (error) {
      console.error('publishGroup failed: ', error);
    }
  }

  removeGroup(key: string, groupType: GroupType): Promise<void> {
    return this.dao.object(DataConstants.DRAFT_GROUPS + key).remove();
  }

  private getGroupIds(
    groupType: GroupType,
    relation?: GroupRelation
  ): Observable<Array<string>> {
    return this.membershipService
      .getGroupIds$(groupType)
      .pipe(
        map((groupIds) =>
          groupIds.has(relation) ? groupIds.get(relation) : []
        )
      );
  }

  private getGroupPath(
    relation: GroupRelation,
    key: string,
    groupType?: GroupType
  ): string {
    switch (relation) {
      case GroupRelation.Drafting:
        return DataConstants.DRAFT_GROUPS + key;
      default:
        return this.authService.getCurrentUser()
          ? DataConstants.PUBLISHED_GROUPS + key
          : DataConstants.PUBLIC_GROUPS + groupType + '/' + key;
    }
  }

  public checkPermissionCreateDraftGroup(
    groupType: GroupType,
    translatedText = 'MissingCanCreateDraftGroupMsg'
  ) {
    if (!this.authService.currentUserhasPublicProfile) {
      return this.authService.notEnoughPermissionOpenProfileBuilder();
    }
    if (!this.authService.canDraftGroup(groupType)) {
      return this.authService.notEnoughPermission(translatedText);
    }
    return this.authService.canDraftGroup(groupType);
  }

  public checkPermissionPublishGroup(
    groupType: GroupType,
    translatedText = 'MissingCanPublishGroupMsg'
  ) {
    if (!this.authService.canPublishGroup(groupType)) {
      this.authService.notEnoughPermission(translatedText);
    }
    return this.authService.canPublishGroup(groupType);
  }

  public async duplicateGroup<T extends Group>(grp: T, postfixName = ' (1)') {
    const duplicated = { ...grp };
    const forum = await this.forumService.createForum(true, true);
    duplicated.name += postfixName;
    duplicated.ownerId = this.authService.getCurrentUser().uid;
    duplicated.forumId = forum.id;
    duplicated.membersCount = 1;
    duplicated.created = new Date();
    duplicated.lastUpdate = null;
    duplicated.publicityDate = null;

    (await this.createGroup(duplicated)) as T;
    switch (duplicated.groupType) {
      case GroupType.Communities:
        this.routingService.navigateToUrlWithDataArray(
          RoutingModel.communities.path,
          ['edit', duplicated.key, 0]
        );
        break;
      case GroupType.Events:
        this.routingService.navigateToUrlWithDataArray(
          RoutingModel.events.path,
          ['edit', duplicated.key, 0]
        );
        break;
      case GroupType.Ideas:
        this.routingService.navigateToUrlWithDataArray(
          RoutingModel.ideas.path,
          ['edit', duplicated.key, 0]
        );
        break;
    }
  }

  public async createGroup(group: Group): Promise<Group> {
    const key = this.dao.createPushId();
    group.key = key;
    await this.dao
      .ref(
        `${DataConstants.MEMBERSHIPS}${group.groupType}/${key}/${this.userId}`
      )
      .set(GroupRelation.Drafting);
    await this.dao
      .ref(
        `${DataConstants.USER_MEMBERSHIPS}${this.userId}/${group.groupType}/${key}`
      )
      .set(GroupRelation.Drafting);
    await this.dao
      .ref(DataConstants.DRAFT_GROUPS + key)
      .set(this.toJson(group));
    return group;
  }

  private toJson(group: Group): IGroup<number> | any {
    switch (group.groupType) {
      case GroupType.Communities:
        return Community.toJson(group as Community);
      case GroupType.Events:
        return Event.toJson(group as Event);
      case GroupType.Ideas:
        return Idea.toJson(group as Idea);
      default:
        break;
    }
  }

  private fromJson(group: IGroup<number>): IGroup<Date> {
    switch (group.groupType) {
      case GroupType.Communities:
        return Community.fromJson(group as ICommunity<number>);
      case GroupType.Events:
        return Event.fromJson(group as IEvent<number>);
      case GroupType.Ideas:
        return Idea.fromJson(group as IIdea<number>);
      default:
        break;
    }
  }
}
