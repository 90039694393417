import { SearchResultV2 } from '../../../../src/app/shared/common/search.model';
import { hardcodedValues } from '../../../hardcodedValues';
import { FormControl } from '@angular/forms';
import { AccountType } from '../consts/accountType';
import { MondoUser } from '../models/user/mondoUser';
import { ScientistUser } from '../models/user/scientistUser';
import { IndustryUser } from '../models/user/industryUser';
import { AccountPermission } from '../consts/accountPermission';
import { Event } from '../../groups/events/models/event';
import { environment } from '../../../environments/environment';
import { RoutingModel } from '../../app.routing-model';
import { Buffer } from 'buffer';
import {
  Category0,
  Category1,
  Experience,
  FieldOfInterest,
  IFilter,
  Subtypes,
  Technique,
  UserFilter,
} from '../models';
import { Group } from '../../groups/models/group';
import { StatusContext } from '../../stepper/shared/model/mondo-status/status-context';

export const translatedMonthText = (month: number): string => {
  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  return hardcodedValues[monthNames[month]];
};

export const extractUserIdsFromChatRoomId = (roomId: string): string[] => {
  return decryptBase64(roomId).split('_');
};

export const findAndGetPublished = (
  statusSnap,
  util,
  path: string,
  handleFunction: (item) => void
) => {
  const statusValue = statusSnap.val();
  const itemsToCheck = new Map();
  const promises = [];
  if (statusSnap.exists && statusValue) {
    Object.keys(statusValue).forEach((key) => {
      const statusContext = StatusContext.fromDbString(statusValue[key]);
      if (statusContext.isPublished()) {
        itemsToCheck.set(key, statusContext);
      }
    });
    itemsToCheck.forEach((status: StatusContext, keyToCheck) => {
      promises.push(
        util.getItem(path, keyToCheck).then((item) => {
          if (item) {
            item.status = status;
            handleFunction(item);
          }
        })
      );
    });
  }
  return Promise.all(promises);
};

export const isSiteExpired = (endDate: Date): boolean => {
  return getDeadlineState(endDate, 365) === Deadline.pastDue;
};

export const isSiteAboutToExpired = (endDate: Date): boolean => {
  return getDeadlineState(endDate, 358) === Deadline.pastDue;
};

export const isCVExpired = (endDate: Date): boolean => {
  return getDeadlineState(endDate, 60) === Deadline.pastDue;
};

export const isCVAboutToExpired = (endDate: Date): boolean => {
  return getDeadlineState(endDate, 30) === Deadline.pastDue;
};

export const isJobAboutToExpire = (endDate: Date): boolean => {
  return getDeadlineState(endDate, 6) === Deadline.pastDue;
};

export const isJobExpired = (endDate: Date): boolean => {
  return getDeadlineState(endDate, 7) === Deadline.pastDue;
};

export const getDeadlineState = (endDate: Date, extraDays = 0): Deadline => {
  if (endDate) {
    const now = new Date();
    now.setHours(0, 0, 0, 0);
    const today = now.getTime();
    const dateChecked = new Date(endDate);
    dateChecked.setDate(dateChecked.getDate() + extraDays);
    const date = dateChecked.getTime();
    if (today < date) {
      return Deadline.notYet;
    }
    if (today === date) {
      return Deadline.today;
    }
    if (today > date) {
      return Deadline.pastDue;
    }
  } else {
    return Deadline.notYet;
  }
};

export enum Deadline {
  notYet = 'published',
  today = 'deadlineToday',
  pastDue = 'deadlineExceeded',
}

export const getEventDeadlineState = (
  endDate: Date,
  extraDays = 0
): EventDeadline => {
  if (endDate) {
    const now = new Date();
    now.setHours(0, 0, 0, 0);
    const today = now.getTime();
    const dateChecked = new Date(endDate);
    dateChecked.setDate(dateChecked.getDate() + extraDays);
    const date = dateChecked.getTime();
    if (today < date) {
      return EventDeadline.notYet;
    }
    if (today === date) {
      return EventDeadline.today;
    }
    if (today > date) {
      return EventDeadline.pastDue;
    }
  } else {
    return EventDeadline.notYet;
  }
};

export enum EventDeadline {
  notYet = 'published',
  today = 'eventDeadlineToday',
  pastDue = 'eventDeadlineExceeded',
}

export const getEventEndedState = (
  endDate: Date,
  extraDays = 0
): EventEnded => {
  if (endDate) {
    const now = new Date();
    now.setHours(0, 0, 0, 0);
    const today = now.getTime();
    const dateChecked = new Date(endDate);
    dateChecked.setDate(dateChecked.getDate() + extraDays);
    const date = dateChecked.getTime();
    if (today < date) {
      return EventEnded.notYet;
    }
    if (today === date) {
      return EventEnded.today;
    }
    if (today > date) {
      return EventEnded.pastDue;
    }
  } else {
    return EventEnded.notYet;
  }
};

export enum EventEnded {
  notYet = 'published',
  today = 'eventEndsToday',
  pastDue = 'eventHasEnded',
}

export const getFormValidationErrors = (form) => {
  let errors = '';
  Object.keys(form.controls).forEach((key) => {
    const controlErrors = form.get(key).errors;
    if (controlErrors != null) {
      Object.keys(controlErrors).forEach((keyError) => {
        errors += `${controlErrors[keyError]}\n`;
      });
    }
  });
  return errors;
};

export const encryptBase64 = (id: string): string => {
  return Buffer.from(id).toString('base64');
};

export const decryptBase64 = (hash: string): string => {
  return Buffer.from(hash, 'base64').toString('ascii');
};

export const isDirtyFields = (item: any, fields: string[]) => {
  let dirty = false;
  const _fields = fields;

  function getFiniteValue(obj) {
    return getProp(obj);

    function getProp(o) {
      let result = false;
      for (const prop of Object.keys(o)) {
        if (!o[prop]) {
          return;
        }
        switch (typeof o[prop]) {
          case 'object':
            if (Array.isArray(o[prop]) && o[prop].length) {
              result = o[prop].length ? !!o[prop].length : result;
            } else {
              result = getProp(o[prop]) ? true : result;
            }
            break;
          case 'boolean':
            result = o[prop] ? o[prop] : result;
            break;
          case 'string':
            result = o[prop].length ? !!o[prop].length : result;
            break;
        }
      }
      return result;
    }
  }

  for (const k in _fields) {
    if (item.hasOwnProperty(_fields[k])) {
      dirty = getFiniteValue(item[_fields[k]]) ? true : dirty;
    }
  }
  return dirty;
};

// fisher yates
export const shuffler = (array) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * i);
    const temp = array[i];
    array[i] = array[j];
    array[j] = temp;
  }
  return array;
};

export const getFiltersFromSearchResults = (
  result: SearchResultV2
): IFilter[] => {
  if (!result || !result.customAttributes) {
    return [];
  }

  let foiList = [];
  let cat0List = [];
  let cat1List = [];
  let cat2List = [];
  let cat3List = [];
  let cat4List = [];
  let cat5List = [];
  let techList = [];
  let siteSubtypeList = [];
  let networkSubtypesList = [];

  if (result.customAttributes['interestsFilters']) {
    foiList = JSON.parse(result.customAttributes['interestsFilters']).map(
      (i: FieldOfInterest) => new UserFilter(i.uid, i.name, 'interests')
    );
  }

  if (result.customAttributes['categories0Filters']) {
    cat0List = JSON.parse(result.customAttributes['categories0Filters']).map(
      (i: Category0) => {
        return new UserFilter(i.uid, i.name, 'category0');
      }
    );
  }
  if (result.customAttributes['categories1Filters']) {
    cat1List = JSON.parse(result.customAttributes['categories1Filters']).map(
      (i: Category1) => {
        return new UserFilter(i.uid, i.name, 'category1');
      }
    );
  }
  if (result.customAttributes['categories2Filters']) {
    cat2List = JSON.parse(result.customAttributes['categories2Filters']).map(
      (i: Category0) => {
        return new UserFilter(i.uid, i.name, 'category2');
      }
    );
  }
  if (result.customAttributes['categories3Filters']) {
    cat3List = JSON.parse(result.customAttributes['categories3Filters']).map(
      (i: Category0) => {
        return new UserFilter(i.uid, i.name, 'category3');
      }
    );
  }
  if (result.customAttributes['categories4Filters']) {
    cat4List = JSON.parse(result.customAttributes['categories4Filters']).map(
      (i: Category0) => {
        return new UserFilter(i.uid, i.name, 'category4');
      }
    );
  }
  if (result.customAttributes['categories5Filters']) {
    cat5List = JSON.parse(result.customAttributes['categories5Filters']).map(
      (i: Category0) => {
        return new UserFilter(i.uid, i.name, 'category5');
      }
    );
  }
  if (result.customAttributes['techniquesFilters']) {
    techList = JSON.parse(result.customAttributes['techniquesFilters']).map(
      (i: Technique) => {
        return new UserFilter(i.id, i.name, 'techniques');
      }
    );
  }
  if (result.customAttributes['networkSubtypesFilters']) {
    networkSubtypesList = JSON.parse(
      result.customAttributes['networkSubtypesFilters']
    ).map((i: Category0) => {
      return new UserFilter(i.uid, i.name, 'networkSubtype');
    });
  }
  if (result.customAttributes['siteSubtypeFilters']) {
    siteSubtypeList = JSON.parse(
      result.customAttributes['siteSubtypeFilters']
    ).map((i: Subtypes) => {
      return new UserFilter(i.id, i.name, 'siteSubtype');
    });
  }
  return [
    ...foiList,
    ...techList,
    ...cat0List,
    ...cat1List,
    ...cat2List,
    ...cat3List,
    ...cat4List,
    ...cat5List,
    ...siteSubtypeList,
    ...networkSubtypesList,
  ];
};

export const getFiltersFromExperience = (exp: Experience): IFilter[] => {
  if (!exp) {
    return [];
  }

  let foiList: UserFilter[] = [];
  let cat0List: UserFilter[] = [];
  let cat1List: UserFilter[] = [];
  let cat2List: UserFilter[] = [];
  let cat3List: UserFilter[] = [];
  let cat4List: UserFilter[] = [];
  let cat5List: UserFilter[] = [];
  let techList: UserFilter[] = [];

  if (exp.interests) {
    foiList = exp.interests.map(
      (i: FieldOfInterest) => new UserFilter(i.uid, i.name, 'interests')
    );
  }

  if (exp.category0) {
    cat0List = exp.category0.map((i: Category0) => {
      return new UserFilter(i.uid, i.name, 'category0');
    });
  }
  if (exp.category1) {
    cat1List = exp.category1.map((i: Category1) => {
      return new UserFilter(i.uid, i.name, 'category1');
    });
  }
  if (exp.category2) {
    cat2List = exp.category2.map((i: Category1) => {
      return new UserFilter(i.uid, i.name, 'category2');
    });
  }
  if (exp.category3) {
    cat3List = exp.category3.map((i: Category1) => {
      return new UserFilter(i.uid, i.name, 'category3');
    });
  }
  if (exp.category4) {
    cat4List = exp.category4.map((i: Category1) => {
      return new UserFilter(i.uid, i.name, 'category4');
    });
  }
  if (exp.category5) {
    cat5List = exp.category5.map((i: Category1) => {
      return new UserFilter(i.uid, i.name, 'category5');
    });
  }
  if (exp.techniques) {
    techList = exp.techniques.map((i: Technique) => {
      return new UserFilter(i.id, i.name, 'techniques');
    });
  }
  return [
    ...foiList,
    ...techList,
    ...cat0List,
    ...cat1List,
    ...cat2List,
    ...cat3List,
    ...cat4List,
    ...cat5List,
  ];
};

export const getFiltersFromGroup = (
  entity: Group
  // excludeCat5 = true
): IFilter[] => {
  if (!entity || !entity.tags) {
    return [];
  }
  let foiList = [];
  let cat0List = [];
  let cat1List = [];
  let cat2List = [];
  let cat3List = [];
  let cat4List = [];
  let cat5List = [];
  let techList = [];
  let networkSubtypesList = [];

  if (entity.tags.fieldsOfInterest) {
    foiList = entity.tags.fieldsOfInterest.map((i: FieldOfInterest) => {
      return new UserFilter(i.uid, i.name, 'interests');
    });
  }

  if (entity.tags.category0) {
    cat0List = entity.tags.category0.map((i: Category0) => {
      return new UserFilter(i.uid, i.name, 'category0');
    });
  }
  if (entity.tags.category1) {
    cat1List = entity.tags.category1.map((i: Category1) => {
      return new UserFilter(i.uid, i.name, 'category1');
    });
  }
  if (entity.tags.category2) {
    cat2List = entity.tags.category2.map((i: Category0) => {
      return new UserFilter(i.uid, i.name, 'category2');
    });
  }
  if (entity.tags.category3) {
    cat3List = entity.tags.category3.map((i: Category0) => {
      return new UserFilter(i.uid, i.name, 'category3');
    });
  }
  if (entity.tags.category4) {
    cat4List = entity.tags.category4.map((i: Category0) => {
      return new UserFilter(i.uid, i.name, 'category4');
    });
  }
  if (entity.tags.category5) {
    cat5List = entity.tags.category5.map((i: Category0) => {
      return new UserFilter(i.uid, i.name, 'category5');
    });
  }
  if (entity.tags.techniques) {
    techList = entity.tags.techniques.map((i: Technique) => {
      return new UserFilter(i.id, i.name, 'techniques');
    });
  }
  if (entity.tags.networkSubtypes) {
    networkSubtypesList = entity.tags.networkSubtypes.map((i: Category0) => {
      return new UserFilter(i.uid, i.name, 'networkSubtype');
    });
  }
  // if (!excludeCat5) {
  //   return [
  //     ...foiList,
  //     ...techList,
  //     ...cat0List,
  //     ...cat1List,
  //     ...cat2List,
  //     ...cat3List,
  //     ...cat4List,
  //     ...cat5List,
  //   ];
  // }
  return [
    ...foiList,
    ...techList,
    ...cat0List,
    ...cat1List,
    ...cat2List,
    ...cat3List,
    ...cat4List,
    ...networkSubtypesList,
  ];
};

export const inRange = (num: number, start: number, end?: number): boolean => {
  // If no end number, use start as end
  if (!end) {
    end = start;
    start = 0;
  }
  return num >= start && num <= end;
};

export const isCVRValid = (cvr: string): boolean => {
  const CVRArray = (cvr + '').split('');
  const weight = [2, 7, 6, 5, 4, 3, 2, 1];
  let sum = 0;
  CVRArray.map((value, i) => {
    sum += +value * weight[i];
  });
  return sum % 11 === 0;
};

export const isEmailValid = (email: string): boolean => {
  const emailRegex = new RegExp(
    /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+$/
  );
  return emailRegex.test(email);
};

export const isCPRValid = (cpr: string): boolean => {
  const CPRArray = (cpr + '').split('');
  const weight = [4, 3, 2, 7, 6, 5, 4, 3, 2, 1];
  let sum = 0;
  CPRArray.map((value, i) => {
    sum += +value * weight[i];
  });
  return sum % 11 === 0;
};

export const StrictEmailValidator = (control: FormControl) => {
  const value = control.value;
  if (value && value.length > 0) {
    return isEmailValid(value)
      ? null
      : {
          email: hardcodedValues.EmailMustBeAnEmailExSupportAtAcademondoCom,
        };
  } else {
    return null;
  }
};

export const CVRValidator = (control: FormControl) => {
  const value = control.value;
  if (value && value.length > 0) {
    return isCVRValid(value)
      ? null
      : { CVR: `${hardcodedValues.cvr} ${hardcodedValues.invalid}` };
  } else {
    return null;
  }
};

export const CPRValidator = (control: FormControl) => {
  const value = control.value;
  if (value && value.length > 0) {
    return isCPRValid(value)
      ? null
      : { CPR: `${hardcodedValues.cpr} ${hardcodedValues.invalid}` };
  } else {
    return null;
  }
};

const isHttpUrlValidSimple = (url: string): boolean => {
  const emailRegex = new RegExp(/^(http(s)?:\/\/.)[^ "]/);
  return emailRegex.test(url);
};

export const StrictWebsiteUrlValidator = (control: FormControl) => {
  const value = control.value;
  if (value && value.length > 0) {
    return isHttpUrlValidSimple(value)
      ? null
      : {
          websiteUrl: `${hardcodedValues.enterAValidUrl}`,
        };
  } else {
    return null;
  }
};

export const canSeeElement = (
  validAccountTypes: AccountType[],
  hardcodedValue: string,
  currentUserType: AccountType
) => {
  const hasValidAccountType = validAccountTypes.includes(currentUserType);
  return hardcodedValues[hardcodedValue] && hasValidAccountType;
};

export const getFromJson = (user, uid, permission: AccountPermission) => {
  switch (user.type) {
    case AccountType.scientist:
    case AccountType.labTech:
    case AccountType.medLabTech:
    case AccountType.ssoAuthedUser:
      return ScientistUser.fromJson(user, uid, permission);
    case AccountType.industry:
    case AccountType.university:
      return IndustryUser.fromJson(user, uid, permission);
  }
};

export const getJsonUser = (user: MondoUser) => {
  switch (user.type) {
    case AccountType.scientist:
    case AccountType.labTech:
    case AccountType.medLabTech:
    case AccountType.ssoAuthedUser:
      return ScientistUser.toJson(user as ScientistUser);
    case AccountType.industry:
    case AccountType.university:
      return IndustryUser.toJson(user as IndustryUser);
  }
};

export const fromNow = (
  date,
  numeric = false,
  duration = false,
  agoText = hardcodedValues.ago
) => {
  const seconds = duration
    ? date / 1000
    : Math.floor((new Date().getTime() - date) / 1000);
  const years = Math.floor(seconds / 31536000);
  const months = Math.floor(seconds / 2592000);
  const days = Math.floor(seconds / 86400);
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor(seconds / 60);

  if (days > 730) {
    return `${years} ${
      numeric ? hardcodedValues.year : hardcodedValues.yearss
    }${agoText}`;
  }
  if (days >= 365 && days <= 729) {
    return `${
      numeric ? '1 ' + hardcodedValues.year : hardcodedValues.aYear
    }${agoText}`;
  }
  if (days >= 60 && days <= 364) {
    return `${months} ${
      numeric ? hardcodedValues.month : hardcodedValues.monthss
    }${agoText}`;
  }
  if (days >= 30 && days <= 59) {
    return `${
      numeric ? '1 ' + hardcodedValues.month : hardcodedValues.aMonth
    }${agoText}`;
  }

  if (hours >= 48 && days <= 29) {
    return `${days} ${
      numeric ? hardcodedValues.day : hardcodedValues.days
    }${agoText}`;
  }
  if (hours >= 24 && hours <= 47) {
    return `${
      numeric ? '1 ' + hardcodedValues.day : hardcodedValues.aDay
    }${agoText}`;
  }

  if (minutes >= 120 && hours <= 23) {
    return `${hours} ${
      numeric ? hardcodedValues.hour : hardcodedValues.hours
    }${agoText}`;
  }
  if (minutes >= 60 && minutes <= 119) {
    return `${
      numeric ? '1 ' + hardcodedValues.hour : hardcodedValues.anHour
    }${agoText}`;
  }
  if (seconds >= 120 && minutes <= 59) {
    return `${minutes} ${
      numeric ? hardcodedValues.minute : hardcodedValues.minutes
    }${agoText}`;
  }
  if (seconds >= 45 && seconds <= 119) {
    return `${
      numeric ? '1 ' + hardcodedValues.minute : hardcodedValues.aMinute
    }${agoText}`;
  }
  if (seconds >= 0 && seconds <= 45) {
    return `${hardcodedValues.aFewSeconds}${agoText}`;
  }
};

const getTechniques = (categories): string => {
  return categories && categories.length > 0
    ? categories.map((category) => category.name).join(', ')
    : '';
};

/**
 *
 * event
 * @return text representation of a VCALENDAR iCal file.<br/>
 * e.g:<br/>
 * <pre>
 BEGIN:VCALENDAR
 PRODID:-//Events Calendar//VENDOR 1.0//DE
 VERSION:2.0
 BEGIN:VEVENT
 DTSTAMP:20181124T153543Z
 DTSTART:20181105T000000
 DTEND:20190915T000000
 SUMMARY:Meeting with ...
 DESCRIPTION:Some description, even
 multiline is possible
 URL:https://www.mydomain.de/context/app/do.it?id=435&nr=30
 UID:20181124T153543Z-uidGen@domain.de
 END:VEVENT
 END:VCALENDAR
 * </pre>
 */
export const createEvent = (
  event: Event,
  organizerEmail = hardcodedValues.senderMail,
  currentUSerEmail = ''
) => {
  const createEventDescription = () => {
    const path = `${environment.academondoUrl}${RoutingModel.events.route}/${event.key}/about`;
    const linkText = `${hardcodedValues.GoTo} ${event.name} @ ${hardcodedValues.academondo}`;
    const link = `<a href="${path}" target="_blank">${linkText}</a>`;

    const about = event.description;

    const description = `${about ? about : ''}\n${link}`;
    const descriptionWithOutSpaces = description.replace(/\n|\r/g, '');

    return descriptionWithOutSpaces;
  };

  const formatDate = (date: Date): string => {
    if (!date) {
      return '';
    }
    // don't use date.toISOString() here, it will be always one day off (cause of the timezone)
    const day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
    const month =
      date.getMonth() < 9 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1;
    const year = date.getFullYear();
    const hour = date.getHours() < 10 ? '0' + date.getHours() : date.getHours();
    const minutes =
      date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();
    const seconds =
      date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
    return `${year}${month}${day}T${hour}${minutes}${seconds}`;
  };
  const separator = '\r\n';
  let VCALENDAR;
  let POSTEVENT;
  let CONDITIONALEVENT;
  let PREEVENT;
  const suffix = 'END:VCALENDAR';
  const timeStamp = formatDate(new Date());

  VCALENDAR = [
    'BEGIN:VCALENDAR',
    'METHOD:PUBLISH',
    'PRODID:ACADEMONDO',
    'VERSION:2.0',
    'BEGIN:VTIMEZONE',
    'TZID:Europe/Copenhagen',
    'X-MICROSOFT-CDO-TZID:3',
    'BEGIN:STANDARD',
    'TZOFFSETFROM:+0200',
    'TZOFFSETTO:+0100',
    // 'RRULE:FREQ=YEARLY;WKST=MO;INTERVAL=1;BYMONTH=10;BYDAY=-1SU',
    'END:STANDARD',
    'BEGIN:DAYLIGHT',
    'TZOFFSETFROM:+0100',
    'TZOFFSETTO:+0200',
    // 'RRULE:FREQ=YEARLY;WKST=MO;INTERVAL=1;BYMONTH=3;BYDAY=-1SU',
    'END:DAYLIGHT',
    'END:VTIMEZONE',
  ];

  PREEVENT = [
    'BEGIN:VEVENT',
    `ORGANIZER;CN=${hardcodedValues.academondo}:mailto:${organizerEmail}`,
    `TZID: Europe/Copenhagen`,
    `DTSTAMP:${timeStamp}`,
    `DTSTART:${formatDate(event.eventPeriod.startDate)}`,
    `DTEND:${formatDate(event.eventPeriod.endDate)}`,
    `SUMMARY:${event.name} - ${hardcodedValues.academondo}`,
    `UID:${event.key}`,
  ];
  CONDITIONALEVENT = [];
  if (!event.isOnline && event.location && event.location.formattedAddress) {
    CONDITIONALEVENT.push(
      `LOCATION;LANGUAGE=en-US:${event.location.formattedAddress}`
    );
  }
  if (event.isOnline && event.meetingLink) {
    CONDITIONALEVENT.push(`LOCATION;LANGUAGE=en-US:${event.meetingLink}`);
  }
  const desc = createEventDescription();
  if (desc) {
    CONDITIONALEVENT.push(`DESCRIPTION;LANGUAGE=en-US:${desc}`);
    CONDITIONALEVENT.push(`X-ALT-DESC;FMTTYPE=text/html:${desc}`);
  }
  if (event.website) {
    CONDITIONALEVENT.push(`URL:${event.website}`);
  }
  POSTEVENT = [
    'CLASS:PUBLIC',
    'PRIORITY:5',
    'TRANSP:OPAQUE',
    'STATUS:CONFIRMED',
    'SEQUENCE:0',
    'BEGIN:VALARM',
    'DESCRIPTION:REMINDER',
    'TRIGGER;RELATED=START:-PT30M',
    'ACTION:DISPLAY',
    'END:VALARM',
    'END:VEVENT',
  ];

  return [
    ...VCALENDAR,
    ...PREEVENT,
    ...CONDITIONALEVENT,
    ...POSTEVENT,
    suffix,
  ].join(separator);
};

export const download = (
  filename,
  text,
  dataType = 'data:text/calendar;charset=utf-8,'
) => {
  const element = document.createElement('a');
  element.setAttribute('href', dataType + encodeURIComponent(text));
  element.setAttribute('download', filename);
  element.setAttribute('target', '_blank');
  // element.setAttribute('rel', 'noopener noreferrer');
  element.style.display = 'none';
  element.click();
};

// sorted Array
// const arr = ['a', 'b', 'c', 'x', 'y', 'z'];
// console.log(binarySearch(arr, 'b'));

export const binarySearch = (arr, target, start = 0, end = arr.length - 1) => {
  // console.log(start, end);

  if (start > end) {
    // console.log('Not found!');
    return -1;
  }

  const middle = Math.floor((start + end) / 2);

  if (arr[middle] === target) {
    // console.log(`${target} Found at index ${middle}`);
    return middle;
  }

  if (arr[middle] > target) {
    return binarySearch(arr, target, start, middle - 1);
  }

  if (arr[middle] < target) {
    return binarySearch(arr, target, middle + 1, end);
  }
};
