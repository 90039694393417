import { Component } from '@angular/core';
import { AuthService } from 'app/core/auth.service';
import { ReactionService } from 'app/core/reaction.service';
import { ReactionBaseComponent } from '../reaction-base/reaction-base.component';

@Component({
  selector: 'app-reaction-comment',
  templateUrl: './reaction-comment.component.html',
  styleUrls: ['./reaction-comment.component.scss'],
})
export class ReactionCommentComponent extends ReactionBaseComponent {
  constructor(reactionService: ReactionService, authService: AuthService) {
    super(reactionService, authService);
  }
}
