import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { SearchResultV2 } from 'app/shared/common/search.model';
import { MembershipService } from 'app/shared-services/membership/membership.service';
import { RoutingModel } from 'app/app.routing-model';
import { GroupRelation } from 'app/groups/models/group-relation';
import { GroupType } from 'app/groups/models/group-type';
import { GroupPrivacy } from 'app/groups/models/groupPrivacy';
import { RoutingService } from 'app/core/routing.service';
import {
  EventDeadline,
  EventEnded,
  fromNow,
  getEventDeadlineState,
  getEventEndedState,
  getFiltersFromSearchResults,
} from '@helpers';
import { Attendance } from 'app/groups/events/models/attendance';
import { IFilter } from 'app/shared/models';

@Component({
  selector: 'app-event-result-viewer',
  templateUrl: './event-result-viewer.component.html',
  styleUrls: ['./event-result-viewer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EventResultViewerComponent implements OnInit {
  @Input() event: SearchResultV2;
  @Input() isAuthed: boolean;
  @Input() showAttendingIcons: boolean;
  @Input() attendance: Map<string, Attendance> = new Map<string, Attendance>();
  @Input() eventRelation: Map<string, GroupRelation> = new Map<
    string,
    GroupRelation
  >();

  loading = false;
  attendances = Attendance;
  groupRelations = GroupRelation;
  filters: IFilter[] = [];
  deadlineState = EventDeadline;
  eventEndedState = EventEnded;
  canOpenDescription = true;
  viewEntireDescription = false;

  constructor(
    private membershipService: MembershipService,
    private routingService: RoutingService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.viewEntireDescription = !this.canOpenDescription;
    this.filters = getFiltersFromSearchResults(this.event);
  }

  toggleDescription(e) {
    // e.preventDefault();
    e.stopPropagation();
    this.viewEntireDescription = !this.viewEntireDescription;
  }

  getStateClass() {
    return this.viewEntireDescription ? 'expanded' : 'collapsed';
  }

  getDeadline(deadline: Date): EventDeadline {
    return getEventDeadlineState(deadline);
  }

  getEventEnded(deadline: Date): EventEnded {
    return getEventEndedState(deadline);
  }

  applyEvent(e, result: SearchResultV2) {
    e.stopPropagation();
    this.loading = true;
    return Promise.all([
      this.membershipService.requestMembership(result.id, GroupType.Events),
      this.membershipService.attendanceChanged(
        result.id,
        Attendance.Interested
      ),
    ]).finally(() => {
      this.loading = false;
      this.cdr.markForCheck();
    });
  }

  hasEventRelation(result: SearchResultV2): boolean {
    return this.getEventRelation(result) !== undefined;
  }

  hasAttendance(result: SearchResultV2): boolean {
    return this.getAttendance(result) !== undefined;
  }

  canApplyEvent(result: SearchResultV2): boolean {
    return (
      this.getEventRelation(result) === undefined &&
      result.customAttributes['privacy'] === GroupPrivacy.Private
    );
  }

  canJoinEvent(result: SearchResultV2): boolean {
    return (
      this.getEventRelation(result) !== GroupRelation.Owner &&
      (result.customAttributes['privacy'] === GroupPrivacy.Public ||
        result.customAttributes['privacy'] === GroupPrivacy.OpenPrivate)
    );
  }

  canAcceptEvent(result: SearchResultV2): boolean {
    return this.getEventRelation(result) === GroupRelation.Member;
  }

  becomeMemberAndChangeAttendance(
    e,
    result: SearchResultV2,
    attendance: Attendance
  ) {
    e.stopPropagation();
    this.loading = true;
    return this.membershipService
      .becomeMemberAndChangeAttendance(result.id, attendance)
      .then(() => this.gotoEvent(result.id));
  }

  async leaveEvent(e, key: string, forumId: string) {
    e.stopPropagation();
    this.loading = true;
    return this.membershipService.leaveEvent(key, forumId).finally(() => {
      this.loading = false;
      this.cdr.markForCheck();
    });
  }

  getEventRelation(result: SearchResultV2): GroupRelation {
    const relation = this.eventRelation.get(result.id);
    return relation;
  }

  getAttendance(result: SearchResultV2): Attendance {
    const attendance = this.attendance.get(result.id);
    return attendance;
  }

  canVisitEvent(result: SearchResultV2) {
    return (
      result.customAttributes['privacy'] === GroupPrivacy.Public ||
      result.customAttributes['privacy'] === GroupPrivacy.OpenPrivate ||
      this.getEventRelation(result) === GroupRelation.Invited ||
      this.getEventRelation(result) === GroupRelation.Member ||
      this.getEventRelation(result) === GroupRelation.Administrator ||
      this.getEventRelation(result) === GroupRelation.Owner
    );
  }

  gotoEvent(key: string) {
    this.routingService.navigateToRoute(RoutingModel.events.path, [
      key,
      'about',
    ]);
  }

  eventDuration(timestamp: number): string {
    return fromNow(timestamp, false, true, '');
  }
}
