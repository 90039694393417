import { SharedService } from './shared.service';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { NavigationService } from 'app/core/navigation.service';
import { AuthGuard } from './auth.guard';
// import { GroupGuard } from './guards/group.guard';
import { AuthService } from './auth.service';
import { CountryService } from './country.service';
import { ErrorService } from './error.service';
import { LoggingService } from './logging.service';
import { MondoFormBuilder } from './mondo-form-builder';
import { PermissionService } from './permission.service';
import { RoutingService } from './routing.service';
import { UserService } from './user.service';
import { SuggestionService } from './suggestion.service';
import { StorageService } from './storage.service';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { DeviceService } from './device.service';
import { RouterEventService } from './router-event.service';
import { StaticItemsService } from './static-items.service';
import { PartnersService } from './partners.service';
import { HelperService } from './helper.service';
import { ActionBarEventService } from './action-bar-event.service';
import { DirectMatchService } from './direct-match.service';
import { SiteService } from './site.service';
import { TimeService } from './time.service';
import { SsoAuthService } from './sso-auth.service';
import { OAuthModule } from 'angular-oauth2-oidc';
import { PostService } from './post.service';
import { ConfigService } from './config.service';
import { DAO } from 'app/shared-services/db-access/dao';
import { CvService } from './cv.service';
import { JobService } from './job.service';
import { SearchService } from './search.service';
import { AppUpdateService } from './app-update.service';
import { SuggestService } from './suggest.service';
import { HttpClientModule } from '@angular/common/http';
import { ReactionService } from './reaction.service';
import { GroupRoleService } from './group-role.service';
import { AppInstallService } from './app-install.service';

@NgModule({
  imports: [
    CommonModule,
    AngularFireAuthModule,
    HttpClientModule,
    DeviceDetectorModule.forRoot(),
    OAuthModule.forRoot(),
  ],
  providers: [
    AuthService,
    StaticItemsService,
    AuthGuard,
    // GroupGuard,
    ErrorService,
    CountryService,
    UserService,
    PermissionService,
    MondoFormBuilder,
    LoggingService,
    NavigationService,
    RoutingService,
    SuggestionService,
    StorageService,
    DeviceService,
    RouterEventService,
    SharedService,
    PartnersService,
    HelperService,
    ActionBarEventService,
    DirectMatchService,
    SiteService,
    TimeService,
    SsoAuthService,
    PostService,
    ConfigService,
    DAO,
    CvService,
    JobService,
    SearchService,
    SiteService,
    AppUpdateService,
    SuggestService,
    ReactionService,
    GroupRoleService,
    AppInstallService,
  ],
})
export class CoreModule {}
