import { Injectable } from '@angular/core';
import { DataConstants } from 'app/shared/consts/dataConstants';
import { Observable } from 'rxjs';
import { UserService } from 'app/core/user.service';
import { DAO } from 'app/shared-services/db-access/dao';
import { EventService } from 'app/groups/events/services/event.service';
import { Event } from 'app/groups/events/models/event';
import { CommunitiesService } from 'app/groups/communities/services/communities.service';
import { Idea } from 'app/groups/project-idea/models/idea';
import { IdeasService } from 'app/groups/project-idea/services/ideas.service';
import { MondoUser } from 'app/shared/models/user/mondoUser';
import { acaConfig } from 'aca-config';
import { map } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { hardcodedValues } from 'hardcodedValues';

declare const google: any;

@Injectable({
  providedIn: 'root',
})
export class MapService {
  static clusterOptions = {
    maxZoom: 8,
    averageCenter: true,
    gridSize: 90,
    minimumClusterSize: 20,
    zoomOnClick: true,
    imagePath: '/assets/icons/m',
  };

  static spiderOptions = {
    markersWontMove: true,
    markersWontHide: true,
    keepSpiderfied: true,
    nearbyDistance: 40,
    circleSpiralSwitchover: 35,
    spiralFootSeparation: 52,
    spiralLengthStart: 22,
    spiralLengthFactor: 8,
    circleFootSeparation: 46,
    circleStartAngle: 3.14 / 12,
    ignoreMapClick: true,
  };

  static mapOptions = {
    // mapId: 'b9562caf09bc30b9',
    // gestureHandling: 'greedy',
    disableDoubleClickZoom: true,
    center: {
      lat: 56.127744, // dk
      lng: 10.202795, // dk
      // lat: 56.45319, // Viborg
      // lng: 9.40201 // Viborg
    },
    minZoom: 1,
    maxZoom: 22,
    // zoom: 5, // europe
    // zoom: 3, // world
    zoom: 8, // 7, // dk
    // zoom: 10, // Viborg
    restriction: {
      latLngBounds: {
        north: 85.0, // restricted
        south: -85.0, // bounds
        west: -180.0, // to
        east: 180.0, // World
        // north: 61.0, // restricted
        // south: 51.5, // bounds
        // west: 1.0, // to
        // east: 21.0 // Denmark
      },
      strictBounds: true,
    },
    streetViewControl: false,
    zoomControl: true,
    styles: [
      {
        featureType: 'poi',

        elementType: 'labels',

        stylers: [
          {
            visibility: 'off',
          },
        ],
      },

      {
        featureType: 'road',

        elementType: 'labels',

        stylers: [
          {
            visibility: 'off',
          },
        ],
      },

      {
        featureType: 'transit',

        elementType: 'labels',

        stylers: [
          {
            visibility: 'off',
          },
        ],
      },

      {
        featureType: 'water',

        elementType: 'geometry.fill',

        stylers: [
          {
            color: '#659df6',
          },
        ],
      },
    ],
  };

  private static mapLoaded = false;

  constructor(
    private db: DAO,
    private userService: UserService,
    private eventService: EventService,
    private ideasService: IdeasService,
    private communitiesService: CommunitiesService
  ) {}

  public load() {
    const promises = [];

    if (!MapService.mapLoaded) {
      MapService.mapLoaded = true;
      promises.push(
        new Promise((resolve) => {
          window['__onGapiLoaded'] = (ev) => {
            // console.info('Google Maps API version: ' + google.maps.version);
            if (
              window['MarkerClusterer'] &&
              window['OverlappingMarkerSpiderfier']
            ) {
              return resolve(window['gapi']);
            } else {
              setTimeout(() => {
                return resolve(window['gapi']);
              }, 100);
            }
          };

          var clustererScript = document.createElement('script');
          clustererScript.async = true;
          clustererScript.type = 'text/javascript';
          clustererScript.src = `https://unpkg.com/@googlemaps/markerclustererplus@1.2.10/dist/index.min.js`;
          var s1 = document.getElementsByTagName('script')[1];
          s1.parentNode.insertBefore(clustererScript, s1);

          var omsScript = document.createElement('script');
          omsScript.async = true;
          omsScript.type = 'text/javascript';
          omsScript.src = `https://cdnjs.cloudflare.com/ajax/libs/OverlappingMarkerSpiderfier/1.0.3/oms.min.js`;
          var s2 = document.getElementsByTagName('script')[2];
          s2.parentNode.insertBefore(omsScript, s2);

          setTimeout(() => {
            var mapScript = document.createElement('script');
            mapScript.defer = true;
            mapScript.type = 'text/javascript';
            mapScript.src = `https://maps.googleapis.com/maps/api/js?key=${environment.googleMapsApiKey}&callback=__onGapiLoaded&libraries=places&region=US&language=${acaConfig.language}`;
            var s = document.getElementsByTagName('script')[0];
            s.parentNode.insertBefore(mapScript, s);
          }, 0);
        })
      );
    }
    return Promise.all(promises);
  }

  getAllSites(): Observable<any> {
    return this.db.list$(DataConstants.PUBLISHED_SITES);
  }

  getUserByType(type: number): Observable<MondoUser[]> {
    return this.userService.getUsersByType(type);
  }

  getPlaceByType(place: string): Observable<any> {
    return this.db.list$(DataConstants.PUBLISHED_PLACES, (ref) =>
      ref.orderByChild('placeInfo/placeType').equalTo(place)
    );
  }

  getPublicCommunities(): Observable<any> {
    return this.communitiesService.getPublicCommunities$(
      !hardcodedValues.EnablePremiumCommunities
    );
  }
  getPublicEvents(): Observable<Event[]> {
    const now = new Date();
    return this.eventService.getPublicEvents$().pipe(
      map((events) => {
        return events.filter((event, index, arr) => {
          return event.eventPeriod.endDate > now;
        });
      })
    );
  }
  getPublicIdeas(): Observable<Idea[]> {
    return this.ideasService.getPublicIdeas$();
  }

  setupMarkerStyles() {
    return {
      u0Marker: {
        url: acaConfig.u0Marker,
        scaledSize: new google.maps.Size(32, 32),
      },
      siteMarker: {
        url: acaConfig.siteMarker,
        scaledSize: new google.maps.Size(32, 32),
      },
      u2Marker: {
        url: acaConfig.u2Marker,
        scaledSize: new google.maps.Size(32, 32),
      },
      u3Marker: {
        url: acaConfig.u3Marker,
        scaledSize: new google.maps.Size(32, 32),
      },
      u4Marker: {
        url: acaConfig.u4Marker,
        scaledSize: new google.maps.Size(32, 32),
      },
      u5Marker: {
        url: acaConfig.u5Marker,
        scaledSize: new google.maps.Size(32, 32),
      },
      gMarker: {
        url: acaConfig.gMarker,
        scaledSize: new google.maps.Size(32, 32),
      },
      cMarker: {
        url: acaConfig.cMarker,
        scaledSize: new google.maps.Size(32, 32),
      },
      eMarker: {
        url: acaConfig.eMarker,
        scaledSize: new google.maps.Size(32, 32),
      },
      iMarker: {
        url: acaConfig.iMarker,
        scaledSize: new google.maps.Size(32, 32),
      },
      nMarker: {
        url: acaConfig.nMarker,
        scaledSize: new google.maps.Size(32, 32),
      },
      p0Marker: {
        url: acaConfig.p0Marker,
        scaledSize: new google.maps.Size(32, 32),
      },
      p1Marker: {
        url: acaConfig.p1Marker,
        scaledSize: new google.maps.Size(32, 32),
      },
      p2Marker: {
        url: acaConfig.p2Marker,
        scaledSize: new google.maps.Size(32, 32),
      },
      p3Marker: {
        url: acaConfig.p3Marker,
        scaledSize: new google.maps.Size(32, 32),
      },
    };
  }
}
