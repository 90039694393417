import { Injectable, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router, RoutesRecognized } from '@angular/router';
import { AuthService } from 'app/core/auth.service';
import { HelperService } from 'app/core/helper.service';
import { RoutingService } from 'app/core/routing.service';
import { StorageService } from 'app/core/storage.service';
import { UserService } from 'app/core/user.service';
import { DAO } from 'app/shared-services/db-access/dao';
import { ConfirmDialogComponent } from 'app/confirm-dialog/confirm-dialog/confirm-dialog.component';
import { DataConstants } from 'app/shared/consts/dataConstants';
import { CV } from 'app/shared/models/cv/cv';
import { PersonalDetails } from 'app/shared/models/cv/personal-details';
import { SortedExperience } from 'app/shared/models/cv/sorted-experience';
import { MondoUser, MondoVersion } from 'app/shared/models/user/mondoUser';
import { ScientistUser } from 'app/shared/models/user/scientistUser';
import {
  Category0,
  Category1,
  FieldOfInterest,
  IFilter,
  Technique,
  UserFilter,
} from 'app/shared/models';
import { CvStepIndex } from 'app/stepper/shared/model/stepIndex';
import { formatText, hardcodedValues } from 'hardcodedValues';
import { BehaviorSubject, Observable, of, Subscription } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';
import { IBaseStepItem } from '../stepper/shared/model/IBaseStepItem';
import { JobExperienceType } from 'app/stepper/shared/model/job-experience-type';
import { DatabaseHandlerService } from '../stepper/shared/services/database-handler.service';
import { AccountType } from 'app/shared/consts/accountType';
import { acaConfig } from 'aca-config';
import { isEmpty } from 'app/shared/common/acaLodash';
import { PublishDialogComponent } from 'app/shared/components/publish-dialog/publish-dialog.component';

@Injectable()
export class CvService extends DatabaseHandlerService implements OnDestroy {
  firstLoad = false;
  subscriptions: Subscription[] = [];
  cvData$: Observable<CV>;
  selectedStep: CvStepIndex = CvStepIndex.personalDetails;
  introCv: CV;
  showIntroViewer$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private cvId: string;
  step: string;
  routeSubscribed = false;
  DoBForStatisticalPurposeOnly = false;

  constructor(
    public db: DAO,
    authService: AuthService,
    private userService: UserService,
    private dialog: MatDialog,
    private routingService: RoutingService,
    private router: Router,
    fnsHelper: HelperService,
    private storageService: StorageService
  ) {
    super(
      db,
      authService,
      DataConstants.DRAFT_CVS,
      DataConstants.USER_CVS,
      DataConstants.PUBLISHED_CVS,
      DataConstants.CV_STATUS,
      fnsHelper
    );
    this.getUrlParams(this.router.url);
    this.subscriptions.push(
      this.router.events.subscribe((data) => {
        if (data instanceof RoutesRecognized) {
          this.getUrlParams(data.state.url);
        }
      })
    );
    // this.checkForOlderVersions();
  }

  getUrlParams(url: string) {
    if (url.split('/').length === 5) {
      this.cvId = url.split('/')[3];
      this.step = url.split('/')[4];
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  getIntroStatus() {
    return this.showIntroViewer$.asObservable();
  }

  public isCvPublished(cvKey: string): Observable<boolean> {
    return this.getItemStatus(cvKey).pipe(
      map((status) => status.isPublished())
    );
  }

  oldDraftCvExists(cvId: string): Promise<boolean> {
    return this.db
      .object<IBaseStepItem>(
        DataConstants.DRAFT_CVS_DEPREECATED +
          this.authService.getCurrentUser().uid +
          '/' +
          cvId
      )
      .snapshotChanges()
      .pipe(
        take(1),
        map((snap) => {
          return !!snap.key;
        })
      )
      .toPromise();
  }

  draftCvExists(cvId: string): Promise<boolean> {
    return this.exists(cvId);
  }

  updateDraftCv(cv: CV): Promise<void> {
    if (!this.firstLoad) {
      this.firstLoad = true;
      return;
    }
    return this.update(cv, CV.toJson);
  }

  createDraftCV(): Promise<string> {
    const cv = new CV();
    cv.name = hardcodedValues.newCV;
    const user = this.authService.getCurrentUser() as ScientistUser;
    cv.personalDetails = user.personalDetails
      ? new PersonalDetails(
          user.personalDetails.firstName,
          user.personalDetails.middleName,
          user.personalDetails.lastName,
          user.personalDetails.sex,
          '',
          '',
          '',
          user.personalDetails.department,
          null,
          user.personalDetails.address,
          user.personalDetails.pictureUrl,
          user.personalDetails.coverUrl,
          user.personalDetails.contactInfo,
          user.personalDetails.currentJobSituation,
          '',
          user.personalDetails.linkedin,
          user.personalDetails.twitter,
          user.personalDetails.github
        )
      : new PersonalDetails();

    if (user.type === AccountType.ssoAuthedUser) {
      const { firstName, lastName } = this.getFirstAndLastNameFromUser(user);
      cv.personalDetails.firstName = firstName;
      cv.personalDetails.lastName = lastName;
    }
    return this.createItemAnStatus(cv, CV.toJson);
  }

  duplicateDraftCv(cv: CV) {
    return this.duplicateItemAndStatus(cv, CV.toJson).then((res) => {
      this.authService.imgSnackbar('duplicateCVDone');
      return res;
    });
  }

  removeCv(key: string): Promise<boolean> {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '500px',
    });
    dialogRef.componentInstance.title = 'deleteCV';
    dialogRef.componentInstance.text = 'areYouSureWantToDeleteX';
    dialogRef.componentInstance.textX = 'cv';
    dialogRef.componentInstance.yesText = 'delete';
    dialogRef.componentInstance.noText = 'cancel';

    return new Promise((resolve, reject) => {
      this.subscriptions.push(
        dialogRef.afterClosed().subscribe((deleteCv) => {
          if (deleteCv) {
            const cvImgPath = `cv/${key}/${key}`;
            try {
              this.storageService.deleteFile(cvImgPath).toPromise();
            } catch (error) {
              console.error('deleteFile failed:', error);
            }
            this.removeItemAndStatus(key)
              .then(() => {
                if (this.authService.getCurrentUser().publishedCv === key) {
                  return this.removePublishedCv();
                }
                return;
              })
              .then(() => resolve(true));
          } else {
            resolve(false);
          }
        })
      );
    });
  }

  getDraftCvs(amount = 50): Observable<CV[]> {
    return this.getDraftItems(CV.fromJson);
  }

  getDraftCv(key: string): Observable<CV> {
    if (key === 'intro') {
      return this.getIntroCV();
    } else {
      return this.getItem<CV>(CV.fromJson, key);
    }
  }

  getIntroCV(): Observable<CV> {
    return this.getItemDeprecated<CV>(CV.fromJson, DataConstants.INTROCV);
  }

  private calcExperience(cv: CV) {
    let workExperience0 = 0;
    let workExperience1 = 0;
    let workExperience2 = 0;
    let workExperience3 = 0;
    let workExperience4 = 0;
    let workExperience5 = 0;
    cv.experience.workExperiences.forEach((item) => {
      if (item.startDate && item.endDate && !item.isCurrent) {
        const daysDiff = this.getDayDifference(item.startDate, item.endDate);
        if (item.jobType === JobExperienceType.experience0) {
          workExperience0 += daysDiff;
        } else if (item.jobType === JobExperienceType.experience1) {
          workExperience1 += daysDiff;
        } else if (item.jobType === JobExperienceType.experience2) {
          workExperience2 += daysDiff;
        } else if (item.jobType === JobExperienceType.experience3) {
          workExperience3 += daysDiff;
        } else if (item.jobType === JobExperienceType.experience4) {
          workExperience4 += daysDiff;
        } else if (item.jobType === JobExperienceType.experience5) {
          workExperience5 += daysDiff;
        }
      } else if (item.startDate && item.isCurrent) {
        const now = new Date();
        const daysDiff = this.getDayDifference(item.startDate, now);
        if (item.jobType === JobExperienceType.experience0) {
          workExperience0 += daysDiff;
        } else if (item.jobType === JobExperienceType.experience1) {
          workExperience1 += daysDiff;
        } else if (item.jobType === JobExperienceType.experience2) {
          workExperience2 += daysDiff;
        } else if (item.jobType === JobExperienceType.experience3) {
          workExperience3 += daysDiff;
        } else if (item.jobType === JobExperienceType.experience4) {
          workExperience4 += daysDiff;
        } else if (item.jobType === JobExperienceType.experience5) {
          workExperience5 += daysDiff;
        }
      }
    });
    cv.experience0 = workExperience0;
    cv.experience1 = workExperience1;
    cv.experience2 = workExperience2;
    cv.experience3 = workExperience3;
    cv.experience4 = workExperience4;
    cv.experience5 = workExperience5;
  }

  private minRequirementForPublish(cv: CV): string {
    let text = '';
    if (this.checkFirstName(cv)) {
      text += `⛔ ${formatText(
        hardcodedValues.youHaveToAddX,
        hardcodedValues.firstName
      )}\n`;
    }
    if (this.checkLastName(cv)) {
      text += `⛔ ${formatText(
        hardcodedValues.youHaveToAddX,
        hardcodedValues.surname
      )}\n`;
    }

    // if (!cv.intro.text.length) {
    //   text += `⛔ ${formatText(
    //     hardcodedValues.youHaveToAddX,
    //     hardcodedValues.introductoryText
    //   )}\n`;
    // }

    // if (this.checkTitle(cv)) {
    //   text += `⛔ ${formatText(
    //     hardcodedValues.youHaveToAddX,
    //     hardcodedValues.academicTitle
    //   )}\n`;
    // }
    // if (this.checkCompanyName(cv)) {
    //   text += `⛔ ${formatText(
    //     hardcodedValues.youHaveToAddX,
    //     hardcodedValues.Facility
    //   )}\n`;
    // }
    // if (this.checkDepartment(cv)) {
    //   text += `⛔ ${formatText(
    //     hardcodedValues.youHaveToAddX,
    //     hardcodedValues.Department
    //   )}\n`;
    // }
    // if (this.checkEmail(cv)) {
    //   text += `⛔ ${formatText(
    //     hardcodedValues.youHaveToAddX,
    //     hardcodedValues.Email
    //   )}\n`;
    // }
    // if (this.checkAddress(cv)) {
    //   text += `⛔ ${formatText(
    //     hardcodedValues.youHaveToAddX,
    //     hardcodedValues.cvAddress
    //   )}\n`;
    // }
    // if (!cv.experience.educations.length) {
    //   text += `⛔ ${formatText(
    //     hardcodedValues.youHaveToAddX,
    //     hardcodedValues.education
    //   )}\n`;
    // }
    // if (!cv.experience.category0.length) {
    //   text +=
    //     '⛔ ' +
    //     formatText(hardcodedValues.youHaveToAddX, hardcodedValues.category0) +
    //     '\n';
    // }
    // if (!cv.experience.techniques.length) {
    //   text +=
    //     '⛔ ' +
    //     formatText(hardcodedValues.youHaveToAddX, hardcodedValues.tech) +
    //     '\n';
    // }
    if (!cv.experience.interests.length) {
      text += `⛔ ${formatText(
        hardcodedValues.youHaveToAddX,
        hardcodedValues.Foi
      )}\n`;
    }
    if (hardcodedValues.registerVisitFunctions) {
      if (!cv.personalDetails.cpr) {
        text += '⛔ ' + hardcodedValues.CprMustBeFilledOut + '\n';
      }
      if (
        hardcodedValues.GraduateProgram &&
        cv.personalDetails.graduateProgram === null
      ) {
        text += '⛔ ' + hardcodedValues.GraduateProgramRequired + '\n';
      }
      if (
        hardcodedValues.Dimittendsats &&
        cv.personalDetails.dimittendsats === null
      ) {
        text += '⛔ ' + hardcodedValues.DimittendsatsRequired + '\n';
      }
      if (
        hardcodedValues.Akademikerkampagnen &&
        cv.personalDetails.akademikerkampagnen === null
      ) {
        text += '⛔ ' + hardcodedValues.AkademikerkampagnenRequired + '\n';
      }
    }
    // if (!cv.personalDetails.dob) {
    //   text += `⛔ ${formatText(
    //     hardcodedValues.youHaveToAddX,
    //     hardcodedValues.dateOfBirth
    //   )}\n`;
    // }
    return text;
  }

  private getRequiredStatusPercentage(cv: CV): number {
    let value = 0;
    let counter = 0;

    if (!this.checkFirstName(cv)) {
      value += 1;
    }
    counter += 1;

    if (!this.checkLastName(cv)) {
      value += 1;
    }
    counter += 1;

    // if (cv.intro.text.length) {
    //   value += 1;
    // }
    // counter += 1;

    // if (!this.checkTitle(cv)) {
    //   value += 1;
    // }
    // counter += 1;

    // if (!this.checkCompanyName(cv)) {
    //   value += 1;
    // }
    // counter += 1;

    // if (!this.checkDepartment(cv)) {
    //   value += 1;
    // }
    // counter += 1;

    // if (!this.checkEmail(cv)) {
    //   value += 1;
    // }
    // counter += 1;

    // if (!this.checkAddress(cv)) {
    //   value += 1;
    // }
    // counter += 1;

    // if (cv.experience.educations.length > 0) {
    //   value += 1;
    // }
    // counter += 1;
    // if (cv.experience.category0.length > 0) {
    //   value += 1;
    // }
    // counter += 1;
    // if (cv.experience.techniques.length > 0) {
    //   value += 1;
    // }
    // counter += 1;
    if (cv.experience.interests.length > 0) {
      value += 1;
    }
    counter += 1;
    if (hardcodedValues.registerVisitFunctions) {
      if (cv.personalDetails.cpr) {
        value += 1;
      }
      counter += 1;
      if (hardcodedValues.GraduateProgram) {
        if (cv.personalDetails.graduateProgram !== null) {
          value += 1;
        }
        counter += 1;
      }
      if (hardcodedValues.Dimittendsats) {
        if (cv.personalDetails.dimittendsats !== null) {
          value += 1;
        }
        counter += 1;
      }
      if (hardcodedValues.Akademikerkampagnen) {
        if (cv.personalDetails.akademikerkampagnen !== null) {
          value += 1;
        }
        counter += 1;
      }
    }
    return Math.floor((value / counter) * 100);
  }

  private checkFirstName(cv: CV): boolean {
    return !cv.personalDetails.firstName.trim();
  }

  private checkLastName(cv: CV): boolean {
    return !cv.personalDetails.lastName.trim();
  }

  private checkEmail(cv: CV): boolean {
    return !cv.personalDetails.contactInfo.email.trim();
  }

  private checkTitle(cv: CV): boolean {
    return !cv.personalDetails.academicTitle.trim();
  }

  private checkCompanyName(cv: CV): boolean {
    return !cv.personalDetails.companyName.trim();
  }

  private checkDepartment(cv: CV): boolean {
    return !cv.personalDetails.department.trim();
  }

  private checkAddress(cv: CV): boolean {
    return (
      !cv.personalDetails.address.street.length ||
      !cv.personalDetails.address.country.code
    );
  }

  public getRequirementStatusAndText(cv: CV) {
    return {
      status: this.getRequiredStatusPercentage(cv),
      text: this.minRequirementForPublish(cv),
    };
  }

  unpublishCV(cv: CV) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '500px',
    });
    dialogRef.componentInstance.title = 'unpublishToRemove';
    dialogRef.componentInstance.yesText = 'unpublish';
    dialogRef.componentInstance.noText = 'cancel';

    return new Promise((resolve) => {
      dialogRef
        .afterClosed()
        .pipe(take(1))
        .toPromise()
        .then(async (unpublishCV) => {
          if (unpublishCV) {
            return this.unpublish(cv).then(() =>
              this.userService
                .removePublicCVFromUser(cv.ownerId)
                .finally(() => {
                  this.authService.notEnoughPermission(
                    hardcodedValues.done,
                    5000
                  );
                })
            );
          } else {
            return;
          }
        });
    });
  }

  public async publishCv(cv: CV): Promise<void> {
    if (cv) {
      this.dialog
        .open(PublishDialogComponent, { width: '500px' })
        .afterClosed()
        .pipe(take(1))
        .subscribe(async (userPublishedCV) => {
          if (userPublishedCV) {
            this.calcExperience(cv);
            const mostRecentExp = this.getMostRecentExp(cv);
            if (!cv.personalDetails.academicTitle && mostRecentExp) {
              cv.personalDetails.academicTitle = mostRecentExp.title;
            }
            if (!cv.personalDetails.companyName && mostRecentExp) {
              cv.personalDetails.companyName = mostRecentExp.locationName;
            }
            const currentUser = this.authService.getCurrentUser();
            const previousPublishedCVKey = currentUser.publishedCv;
            if (currentUser.type === AccountType.ssoAuthedUser) {
              const { firstName, lastName } =
                this.getFirstAndLastNameFromUser(currentUser);
              cv.personalDetails.firstName = firstName;
              cv.personalDetails.lastName = lastName;
            }
            cv.status.queueForPublish();
            await this.handlePersonalDetailsChange(cv);
            cv.personalDetails.cpr = null;
            if (cv.personalDetails.exclude) {
              cv.personalDetails = new PersonalDetails();
              cv.personalDetails.firstName = hardcodedValues.Anonymous;
              cv.personalDetails.exclude = true;
            }
            if (this.DoBForStatisticalPurposeOnly) {
              const dob = cv.personalDetails.dob;
              // todo
              cv.personalDetails.dob = undefined;
            }
            if (
              !isEmpty(previousPublishedCVKey) &&
              cv.key !== previousPublishedCVKey
            ) {
              await Promise.resolve().then(() =>
                this.getDraftCv(previousPublishedCVKey)
                  .pipe(take(1))
                  .subscribe((prevCv) => {
                    if (prevCv) {
                      prevCv.status.unpublish();
                      this.updateStatus(previousPublishedCVKey, prevCv.status);
                    }
                  })
              );
            }
            this.publish(cv, CV.toJson);
            return this.routingService.navigateToUserDefault(
              this.authService.getUserStatus()
            );
          }
        });
    }
  }

  private getFirstAndLastNameFromUser(user: MondoUser) {
    const recipientName = user.displayName;
    const lastWordIndex = recipientName.lastIndexOf(' ');
    const firstName =
      lastWordIndex === -1
        ? recipientName
        : recipientName.substring(0, lastWordIndex);
    const lastName =
      lastWordIndex === -1
        ? firstName
        : recipientName.substring(lastWordIndex + 1);
    return { firstName: firstName, lastName: lastName };
  }

  private isFiltersEqual(filter1: IFilter[], filter2: IFilter[]) {
    // console.log(filter1)
    // console.log(filter2)
    // console.log(JSON.stringify(filter1) === JSON.stringify(filter2))
    return JSON.stringify(filter1) === JSON.stringify(filter2);
  }

  private async handlePersonalDetailsChange(cv: CV): Promise<void> {
    const user = this.authService.getCurrentUser() as ScientistUser;
    const newFilters = this.getFilters(cv);
    // if (
    //   !this.isPersonnalDetailsEqual(
    //     user.personalDetails,
    //     cv.personalDetails
    //   ) ||
    //   !this.isFiltersEqual(newFilters, user.filters)
    // ) {
    if (cv.personalDetails.exclude) {
      await this.userService.setUserCPR(cv.personalDetails.cpr, user.uid);
      user.displayName = '';
      user.personalDetails = new PersonalDetails();
      user.personalDetails.exclude = true;
      user.coverUrl = null;
      user.photoURL = null;
      user.filters = newFilters;
      user.publishedCv = cv.key;
      user.disableChat = cv.personalDetails.disableChat
        ? cv.personalDetails.disableChat
        : false;
      await this.userService.updateUser(user);
      return this.userService.setUserPersonalDetails(
        cv.personalDetails,
        user.uid
      );
    } else {
      await this.userService.setUserCPR(cv.personalDetails.cpr, user.uid);
      user.displayName = `${cv.personalDetails.firstName} ${
        acaConfig.showMiddleName && cv.personalDetails.middleName
          ? cv.personalDetails.middleName + ' '
          : ''
      }${cv.personalDetails.lastName}`;
      user.personalDetails = cv.personalDetails;
      user.personalDetails.cpr = null;
      user.personalDetails.pictureUrl = cv.personalDetails.pictureUrl;
      user.coverUrl = cv.personalDetails.coverUrl;
      user.photoURL = cv.personalDetails.pictureUrl;
      user.filters = newFilters;
      user.publishedCv = cv.key;
      user.disableChat = cv.personalDetails.disableChat
        ? cv.personalDetails.disableChat
        : false;
      return this.userService.updateUser(user);
    }
  }

  // private isPersonnalDetailsEqual(
  //   p1: PersonalDetails,
  //   p2: PersonalDetails
  // ): boolean {
  //   return !Object.keys(p1).some((key) => !isEqual(p1[key], p2[key]));
  // }

  public getCurrentUsersPublishedCv(): Observable<CV> {
    return this.getPublishedCv(this.authService.getCurrentUser().publishedCv);
  }

  public getPublishedCv(cvKey: string): Observable<CV> {
    return this.getItem<CV>(CV.fromJson, cvKey, true);
  }

  public getPublishedCVFromUserId(userId: string): Observable<CV> {
    return this.authService.readUserByUid(userId).pipe(
      switchMap((user: MondoUser) => {
        return user && user.publishedCv
          ? this.getPublishedCv(user.publishedCv)
          : of(null);
      })
    );
  }

  public async removePublishedCv() {
    const user = this.authService.getCurrentUser();

    await this.db.object(this.getCvPath(user.publishedCv)).remove();
    user['personalDetails'] = null;
    user.displayName = '';
    user.publishedCv = '';
    user.photoURL = '';
    user.coverUrl = '';
    user.filters = [];
    return this.userService.updateUser(user);
  }

  private getCvPath(cvKey: string) {
    return DataConstants.PUBLISHED_CVS + '/' + cvKey;
  }

  private getDayDifference(startDate: Date, endDate: Date) {
    return (endDate.getTime() - startDate.getTime()) / 1000 / 60 / 60 / 24;
  }

  private checkForOlderVersions() {
    this.authService
      .getCurrentUser$()
      .pipe(take(1))
      .subscribe((user) => {
        if (user && user.version === MondoVersion.version1) {
          let cvsToRemove = [];
          const promises: Promise<string>[] = [];
          new Promise((resolve, reject) => {
            this.getItems<CV>(
              1000,
              CV.fromJson,
              DataConstants.DRAFT_CVS_DEPREECATED +
                this.authService.getCurrentUser().uid +
                '/'
            )
              .pipe(take(1))
              .subscribe((cvs) => {
                cvsToRemove = cvs;
                cvs.forEach((cv) => {
                  promises.push(this.duplicateDraftCv(cv));
                });
                Promise.all(promises).then(() => {
                  resolve('done');
                });
              });
          }).then(() => {
            cvsToRemove.forEach((cv) => {
              return this.db
                .object(
                  DataConstants.DRAFT_CVS_DEPREECATED +
                    this.authService.getCurrentUser().uid +
                    '/' +
                    cv.key
                )
                .remove();
            });
            user.version = MondoVersion.version2;
            this.userService.updateUser(user);
          });
        }
      });
  }

  getExistSites() {
    return this.getExistItems();
  }

  getNumberPublishedCvs(): Observable<number> {
    return this.getDraftCvs().pipe(
      map((cvs) => {
        const published = cvs.filter((cv) => cv.status.isPublished());
        return published.length;
      })
    );
  }

  getMostRecentExp(cv: CV): SortedExperience {
    const workAndEducationExperiences = this.getWorkAndEducationExperiences(cv);
    if (
      workAndEducationExperiences &&
      workAndEducationExperiences.length > 0 &&
      workAndEducationExperiences[0]
    ) {
      const current = workAndEducationExperiences.filter(
        (exp) => exp && exp.isCurrent
      );
      const first = current[0];
      const bestBet = first ? first : workAndEducationExperiences[0];
      return bestBet;
    }
  }

  getRecentExperience(cv: CV, showIfCurrent = true): string {
    const best = this.getMostRecentExp(cv);
    let result = '';
    if (best) {
      if (best.title) {
        result += best.title;
      }
      if (best.department) {
        result += ` at ${best.department}`;
      }
      if (best.locationName) {
        result += `, ${best.locationName}`;
      }
      if (best.country && best.country.code) {
        result += `, ${best.country.code}`;
      }
      if (best.isCurrent && showIfCurrent) {
        result += ` (${hardcodedValues.current})`;
      }
    }
    return result;
  }

  private getWorkAndEducationExperiences(cv: CV): SortedExperience[] {
    const workExp = cv ? cv.experience.workExperiences : [];
    const educaExp = cv ? cv.experience.educations : [];
    const researchExp = cv ? cv.experience.researches : [];
    const works = workExp.map((work) => {
      if (work.jobTitle) {
        return new SortedExperience(
          work,
          work.jobTitle,
          undefined,
          work.company,
          work.country,
          work.isCurrent
        );
      }
    });
    const educas = educaExp.map((edu) => {
      if (edu.eduTitle) {
        return new SortedExperience(
          edu,
          edu.eduTitle,
          edu.department,
          edu.university,
          edu.country,
          edu.isCurrent
        );
      }
    });
    const research = researchExp.map((res) => {
      if (res.projectTitle) {
        return new SortedExperience(
          res,
          res.projectTitle,
          res.department,
          res.university,
          res.country,
          res.isCurrent
        );
      }
    });
    const all = works.concat(educas).concat(research);
    return all.length > 0
      ? all.sort((a, b) =>
          this.getTimeSortNumber(a.exp.startDate, b.exp.startDate)
        )
      : null;
  }

  getFilters(cv: CV): IFilter[] {
    if (!cv || !cv.experience) {
      return [];
    }
    let foiList = [];
    let cat0List = [];
    let cat1List = [];
    let cat2List = [];
    let cat3List = [];
    let cat4List = [];
    let cat5List = [];
    let techList = [];

    if (cv.experience.interests) {
      foiList = cv.experience.interests.map((i: FieldOfInterest) => {
        return new UserFilter(i.uid, i.name, 'interests');
      });
    }

    if (cv.experience.category0) {
      cat0List = cv.experience.category0.map((i: Category0) => {
        return new UserFilter(i.uid, i.name, 'category0');
      });
    }
    if (cv.experience.category1) {
      cat1List = cv.experience.category1.map((i: Category1) => {
        return new UserFilter(i.uid, i.name, 'category1');
      });
    }
    if (cv.experience.category2) {
      cat2List = cv.experience.category2.map((i: Category0) => {
        return new UserFilter(i.uid, i.name, 'category2');
      });
    }
    if (cv.experience.category3) {
      cat3List = cv.experience.category3.map((i: Category0) => {
        return new UserFilter(i.uid, i.name, 'category3');
      });
    }
    if (cv.experience.category4) {
      cat4List = cv.experience.category4.map((i: Category0) => {
        return new UserFilter(i.uid, i.name, 'category4');
      });
    }
    if (cv.experience.category5) {
      cat5List = cv.experience.category5.map((i: Category0) => {
        return new UserFilter(i.uid, i.name, 'category5');
      });
    }
    if (cv.experience.techniques) {
      techList = cv.experience.techniques.map((i: Technique) => {
        return new UserFilter(i.id, i.name, 'techniques');
      });
    }
    return [
      ...foiList,
      ...techList,
      ...cat0List,
      ...cat1List,
      ...cat2List,
      ...cat3List,
      ...cat4List,
      ...cat5List,
    ];
  }

  private getTimeSortNumber(dateA: Date, dateB: Date): number {
    const aTime = dateA ? dateA.getTime() : -Infinity;
    const bTime = dateB ? dateB.getTime() : -Infinity;
    return bTime - aTime;
  }
}
