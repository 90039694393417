import {
  Component,
  Input,
  ChangeDetectionStrategy,
  OnInit,
} from '@angular/core';
import { MatDialog } from '@angular/material';
import { JobService } from 'app/core/job.service';
import { AcaCarouselComponent } from 'app/shared-ui/aca-carousel/aca-carousel.component';
import {
  Category0,
  Category1,
  FieldOfInterest,
  IFilter,
  Technique,
  UserFilter,
} from 'app/shared/models';
import { Job } from 'app/stepper/job/model/job';

@Component({
  selector: 'app-job-viewer',
  templateUrl: './job-viewer.component.html',
  styleUrls: ['./job-viewer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class JobViewerComponent implements OnInit {
  @Input() showMetaData = true;
  @Input() job: Job;

  constructor(private dialog: MatDialog, private jobService: JobService) {}

  ngOnInit(): void {}

  get filters() {
    return this.jobService.getFilters(this.job);
  }

  openImages(job: Job) {
    const slideShow = [];
    job.uploads.forEach((upload) => {
      slideShow.push({ img: upload.url });
    });
    const config = {
      panelClass: 'job-carousel-dialog',

      data: { slides: slideShow },
    };

    this.dialog.open(AcaCarouselComponent, config);
  }
}
