import { AccountPermission } from './shared/consts/accountPermission';
import { AccountType } from './shared/consts/accountType';
import { hardcodedValues } from '../hardcodedValues';
import { acaConfig } from '../aca-config';
import { acaNames } from '../aca-names';
import { SearchableTypes } from './shared/common/search.model';

const onlyScientistTypes = [
  AccountType.scientist,
  AccountType.medLabTech,
  AccountType.labTech,
  AccountType.ssoAuthedUser,
];
const onlyCompanyTypes = [AccountType.industry, AccountType.university];
const allTypes = onlyScientistTypes.concat(onlyCompanyTypes);

export enum MondoRoutes {
  aboutSkuffen = 'aboutSkuffen',
  aboutNad = 'aboutNad',
  dashboard = 'dashboard',
  // home = 'home',
  // aboutUs = 'about',
  // jobBuilder = 'jobBuilder',
  scienceMatch = 'scienceMatch',
  scienceMatchJob = 'scienceMatchJob',
  scienceMatchSite = 'scienceMatchSite',
  scienceMatchCommunities = 'scienceMatchCommunities',
  scienceMatchEvents = 'scienceMatchEvents',
  scienceMatchIdeas = 'scienceMatchIdeas',
  map = 'map',
  // calendar = 'calendar',
  jobBuilder = 'jobBuilder',
  cvBuilder = 'cvBuilder',
  aboutUs = 'about',
  aboutPlatform = 'aboutPlatform',
  tutorials = 'tutorials',
  events = 'events',
  profileEditor = 'profile-editor',
  job = 'job',
  none = 'none',
  root = '/',
  link = 'link',
  communities = 'communities',
  ideas = 'ideas',
  homeProfile = 'homeProfile',
  siteBuilder = 'siteBuilder',
  cookies = 'cookies',
  newUser = 'new-user',
  emailActionManager = 'email-action-manager',
  login = 'login',
  signup = 'signup',
  landingPage = 'landing-page',
  chat = 'chat',
  kanban = 'kanban',
  playground = 'playground',
  wptools = 'wptools',
  wp = 'wp',
  wp2 = 'wp2',
  multiwp = 'multiwp',
  home = 'home',
  adminPage = 'adminPage',
  gdpr = 'gdpr',
  ai = 'ai',
}

export const NewUserRoutes = {
  scienceMatch: 'scienceMatch',
  map: 'map',
  // aboutUs: 'about',
  // events: 'events',
  // communities: 'communities',
  // ideas: 'ideas',
  // tutorials: 'tutorials',
  // aboutJobSearch: 'aboutJobSearch',
  // aboutJobBuilder: 'aboutJobBuilder',
};

export const RoutingModel = {
  cvBuilder: {
    excludeFromNavBar: true,
    title: hardcodedValues.CVBuilderTitle,
    path: hardcodedValues.CVBuilderRoute,
    route: '/' + hardcodedValues.CVBuilderRoute,
    icon: 'account_circle',
    routeData: {
      validAccountTypes: onlyScientistTypes,
      minimumPermission: AccountPermission.basic,
      animation: 'cv-builder',
    },
  },
  jobBuilder: {
    title: hardcodedValues.jobBuilderTitle,
    path: hardcodedValues.jobBuilderRoute,
    route: '/' + hardcodedValues.jobBuilderRoute,
    icon: 'work',
    routeData: {
      validAccountTypes: acaConfig.enableJobs ? allTypes : [],
      minimumPermission: AccountPermission.basic,
      animation: 'job-builder',
    },
  },
  adminPage: {
    excludeFromNavBar: true,
    title: hardcodedValues.adminRoute,
    path: hardcodedValues.adminRoute,
    route: '/' + hardcodedValues.adminRoute,
    icon: 'miscellaneous_services',
    routeData: {
      validAccountTypes: allTypes,
      minimumPermission: AccountPermission.admin,
      animation: 'admin-page',
    },
    lazy: './admin-page/admin-page.module#AdminPageModule',
  },
  gdpr: {
    excludeFromNavBar: true,
    title: 'gdpr',
    path: 'gdpr',
    route: '/gdpr',
    icon: 'miscellaneous_services',
    routeData: {
      validAccountTypes: allTypes,
      minimumPermission: AccountPermission.basic,
      animation: 'gdpr',
    },
  },
  ai: {
    excludeFromNavBar: true,
    title: 'ai',
    path: 'ai',
    route: '/ai',
    icon: 'miscellaneous_services',
    routeData: {
      validAccountTypes: allTypes,
      minimumPermission: AccountPermission.admin,
      animation: 'ai',
    },
  },
  profileEditor: {
    excludeFromNavBar: true,
    title: hardcodedValues.profileEditorRoute,
    path: 'settings',
    route: '/settings',
    icon: 'insert_drive_file',
    routeData: {
      validAccountTypes: allTypes,
      minimumPermission: AccountPermission.basic,
      animation: 'profile-editor',
    },
  },
  communities: {
    excludeFromNavBar: true,
    title: hardcodedValues.CommunitiesTitle,
    path: '_communities',
    route: '/_communities',
    icon: 'groups',
    routeData: {
      validAccountTypes: acaConfig.enableCommunities
        ? [AccountType.scientist, AccountType.industry]
        : [],
      minimumPermission: AccountPermission.basic,
      animation: '_communities',
    },
  },
  ideas: {
    excludeFromNavBar: true,
    title: acaNames.IdeasTitle,
    path: '_ideas',
    route: '/_ideas',
    icon: acaNames.IdeasIcon,
    routeData: {
      validAccountTypes: [AccountType.scientist, AccountType.industry],
      minimumPermission: AccountPermission.basic,
      animation: '_ideas',
    },
  },
  events: {
    excludeFromNavBar: true,
    title: hardcodedValues.EventTitle,
    path: '_events',
    route: '/_events',
    icon: 'events',
    routeData: {
      validAccountTypes: acaConfig.enableEvents ? allTypes : [],
      minimumPermission: AccountPermission.basic,
      animation: '_events',
    },
  },
  link: {
    excludeFromNavBar: true,
    title: hardcodedValues.profileRoute,
    path: 'link',
    route: '/link',
    icon: 'emoji_people',
    routeData: {
      validAccountTypes: allTypes,
      minimumPermission: AccountPermission.basic,
      animation: 'link',
    },
  },
  job: {
    excludeFromNavBar: true,
    title: hardcodedValues.jobRoute,
    path: 'job',
    route: '/job',
    icon: 'insert_drive_file',
    routeData: {
      validAccountTypes: allTypes,
      minimumPermission: AccountPermission.basic,
      animation: 'job',
    },
  },
  map: {
    title: hardcodedValues.mapTitle,
    path: hardcodedValues.mapRoute,
    route: '/' + hardcodedValues.mapRoute,
    icon: 'map',
    showSpacer: true,
    routeData: {
      validAccountTypes: allTypes,
      minimumPermission: AccountPermission.basic,
      animation: 'map',
    },
  },
  newUser: {
    excludeFromNavBar: true,
    title: hardcodedValues.newUserRoute,
    path: 'new-user',
    route: '/new-user',
    icon: 'group',
    routeData: {
      validAccountTypes: allTypes,
      minimumPermission: AccountPermission.basic,
      animation: 'new-user',
    },
  },
  aboutUs: {
    excludeFromNavBar: true,
    title: hardcodedValues.aboutUsRoute,
    path: 'about/us',
    route: '/about/us',
    icon: 'group',
    showSpacer: true,
    routeData: {
      validAccountTypes: allTypes,
      minimumPermission: AccountPermission.basic,
      animation: 'aboutUs',
    },
  },
  aboutPlatform: {
    excludeFromNavBar: true,
    title: hardcodedValues.aboutUsRoute,
    path: 'about/platform',
    route: '/about/platform',
    icon: 'group',
    showSpacer: true,
    routeData: {
      validAccountTypes: allTypes,
      minimumPermission: AccountPermission.basic,
      animation: 'aboutPlatform',
    },
  },
  scienceMatchJob: {
    excludeFromNavBar: true,
    title: `Waste`,
    path: hardcodedValues.scienceMatchRoute,
    route: '/' + hardcodedValues.scienceMatchRoute + '/' + SearchableTypes.Job,
    icon: 'recycling',
    noReuse: true,
    routeData: {
      validAccountTypes: allTypes,
      minimumPermission: AccountPermission.basic,
      animation: 'scienceMatchJob',
    },
  },
  scienceMatchSite: {
    excludeFromNavBar: true,
    title: `Find ${hardcodedValues.site}`,
    path: hardcodedValues.scienceMatchRoute,
    route: '/' + hardcodedValues.scienceMatchRoute + '/' + SearchableTypes.Site,
    icon: 'search',
    noReuse: true,
    routeData: {
      validAccountTypes: allTypes,
      minimumPermission: AccountPermission.basic,
      animation: 'scienceMatchSite',
    },
  },
  scienceMatchCommunities: {
    excludeFromNavBar: true,
    title: `Find ${hardcodedValues.community}`,
    path: hardcodedValues.scienceMatchRoute,
    route:
      '/' + hardcodedValues.scienceMatchRoute + '/' + SearchableTypes.Community,
    icon: 'groups',
    noReuse: true,
    routeData: {
      validAccountTypes: allTypes,
      minimumPermission: AccountPermission.basic,
      animation: 'scienceMatchCommunities',
    },
  },
  scienceMatchEvents: {
    excludeFromNavBar: true,
    title: `Find ${hardcodedValues.event}`,
    path: hardcodedValues.scienceMatchRoute,
    route:
      '/' + hardcodedValues.scienceMatchRoute + '/' + SearchableTypes.Event,
    icon: 'events',
    noReuse: true,
    routeData: {
      validAccountTypes: allTypes,
      minimumPermission: AccountPermission.basic,
      animation: 'scienceMatchEvents',
    },
  },
  scienceMatchIdeas: {
    excludeFromNavBar: true,
    title: `Find ${hardcodedValues.idea}`,
    path: hardcodedValues.scienceMatchRoute,
    route: '/' + hardcodedValues.scienceMatchRoute + '/' + SearchableTypes.Idea,
    icon: acaNames.IdeasIcon,
    noReuse: true,
    routeData: {
      validAccountTypes: allTypes,
      minimumPermission: AccountPermission.basic,
      animation: 'scienceMatchIdeas',
    },
  },
  aboutSkuffen: {
    excludeFromNavBar: true,
    title: 'Skuffesiden',
    path: 'about/skuffesiden',
    route: '/about/skuffesiden',
    icon: 'group',
    showSpacer: true,
    routeData: {
      validAccountTypes: allTypes,
      minimumPermission: AccountPermission.admin,
      animation: 'aboutSkuffen',
    },
  },
  aboutNad: {
    excludeFromNavBar: true,
    title: 'Nad',
    path: 'about/nad',
    route: '/about/nad',
    icon: 'group',
    showSpacer: true,
    routeData: {
      validAccountTypes: allTypes,
      minimumPermission: AccountPermission.basic,
      animation: 'aboutNad',
    },
  },
  tutorials: {
    title: hardcodedValues.about,
    path: 'tutorials',
    route: '/tutorials',
    icon: 'group',
    external: 'https://link.to/external',
    routeData: {
      validAccountTypes: [],
      minimumPermission: AccountPermission.basic,
      animation: 'tutorials',
    },
  },
  aboutJobSearch: {
    excludeFromNavBar: true,
    title: hardcodedValues.aboutJobSearchRoute,
    path: 'about/jobSearch',
    route: 'about/jobSearch',
    icon: 'account_balance',
    showSpacer: false,
    routeData: {
      validAccountTypes: allTypes,
      minimumPermission: AccountPermission.basic,
      animation: 'aboutJobSearch',
    },
  },
  aboutJobBuilder: {
    excludeFromNavBar: true,
    title: hardcodedValues.aboutJobBuilderRoute,
    path: 'about/jobBuilder',
    route: 'about/jobBuilder',
    icon: 'work',
    showSpacer: true,
    routeData: {
      validAccountTypes: allTypes,
      minimumPermission: AccountPermission.basic,
      animation: 'aboutJobBuilder',
    },
  },
  login: {
    excludeFromNavBar: true,
    title: hardcodedValues.login,
    path: 'login',
    route: 'login',
    icon: 'login',
    showSpacer: false,
    routeData: {
      // validAccountTypes: allTypes,
      // minimumPermission: AccountPermission.basic,
      animation: 'login',
    },
  },
  signup: {
    excludeFromNavBar: true,
    title: hardcodedValues.signUp,
    path: 'signup',
    route: 'signup',
    icon: 'how_to_reg',
    showSpacer: true,
    routeData: {
      // validAccountTypes: allTypes,
      // minimumPermission: AccountPermission.basic,
      animation: 'signup',
    },
  },
  chat: {
    excludeFromNavBar: true,
    title: hardcodedValues.chatRoute,
    path: 'chat',
    route: 'chat',
    icon: 'chat',
    routeData: {
      validAccountTypes: hardcodedValues.enableChat ? allTypes : [],
      minimumPermission: AccountPermission.basic,
      animation: 'chat',
    },
  },
  playground: {
    excludeFromNavBar: true,
    title: 'Playground',
    path: 'playground',
    route: 'playground',
    icon: 'accessible_forward',
    routeData: {
      validAccountTypes: allTypes,
      minimumPermission: AccountPermission.admin,
      animation: 'playground',
    },
  },
  wp: {
    excludeFromNavBar: acaConfig['urlForWordpressnews'] ? false : true,
    title: acaConfig['TitleForWordpressnews'],
    path: 'wp',
    route: '/wp/news',
    icon: 'newspaper',
    routeData: {
      validAccountTypes: acaConfig['urlForWordpressnews'] ? allTypes : [],
      minimumPermission: AccountPermission.basic,
      animation: 'news',
    },
  },
  wp2: {
    excludeFromNavBar: acaConfig['urlForWordpressnews2'] ? false : true,
    title: acaConfig['TitleForWordpressnews2'],
    path: 'wp',
    route: '/wp/news2',
    icon: 'newspaper',
    routeData: {
      validAccountTypes: acaConfig['urlForWordpressnews2'] ? allTypes : [],
      minimumPermission: AccountPermission.basic,
      animation: 'news',
    },
  },
  multiwp: {
    excludeFromNavBar: acaConfig['EnableMultiwordpressFeeds'] ? false : true,
    title: acaConfig['TitleForMultiwordpressFeeds'],
    path: 'wp',
    route: '/wp/multi',
    icon: 'newspaper',
    routeData: {
      validAccountTypes: acaConfig['EnableMultiwordpressFeeds'] ? allTypes : [],
      minimumPermission: AccountPermission.basic,
      animation: 'news',
    },
  },
  wptools: {
    excludeFromNavBar: acaConfig['urlForWordpresstools'] ? false : true,
    title: acaConfig['TitleForWordpresstools'],
    path: 'wp',
    route: '/wp/tools',
    icon: 'build',
    routeData: {
      validAccountTypes: acaConfig['urlForWordpresstools'] ? allTypes : [],
      minimumPermission: AccountPermission.basic,
      animation: 'tools',
    },
  },
  cookies: {
    excludeFromNavBar: true,
    title: hardcodedValues.cookiesRoute,
    path: 'cookies',
    route: '/cookies',
    icon: 'cookies',
    routeData: {
      validAccountTypes: allTypes,
      minimumPermission: AccountPermission.basic,
      animation: 'cookies',
    },
  },
  dashboard: {
    excludeFromNavBar: false,
    title: hardcodedValues.dashboardTitle,
    path: hardcodedValues.dashboardRoute,
    route: '/' + hardcodedValues.dashboardRoute,
    icon: 'home',
    routeData: {
      validAccountTypes: allTypes,
      minimumPermission: AccountPermission.basic,
      animation: 'dashboard',
    },
  },
  home: {
    excludeFromNavBar: true,
    title: hardcodedValues.siteBuilderRoute,
    path: 'home',
    route: '/home',
    icon: 'business',
    routeData: {
      validAccountTypes: onlyCompanyTypes,
      minimumPermission: AccountPermission.basic,
      animation: 'home',
    },
  },
  siteBuilder: {
    excludeFromNavBar: true,
    root: 'Site',
    hint: hardcodedValues.Site,
    title: hardcodedValues.siteBuilderRoute,
    path: 'site-builder',
    route: '/home/site-builder',
    icon: 'edit',
    routeData: {
      validAccountTypes: onlyCompanyTypes,
      minimumPermission: AccountPermission.basic,
      animation: 'siteBuilder',
    },
    page: 'home',
  },
  homeProfile: {
    excludeFromNavBar: true,
    hint: hardcodedValues.CV,
    root: 'profile',
    title: hardcodedValues.homeProfileRoute,
    path: 'profile',
    route: '/home/profile',
    icon: 'edit',
    routeData: {
      validAccountTypes: [],
      minimumPermission: AccountPermission.basic,
      animation: 'homeProfile',
    },
    page: 'home',
  },
  scienceMatch: {
    excludeFromNavBar: false,
    title: hardcodedValues.scienceMatch,
    path: hardcodedValues.scienceMatchRoute,
    route: '/' + hardcodedValues.scienceMatchRoute,
    icon: 'search',
    // iconUrl: '/assets/logos/AM.svg',
    noReuse: true, // TBD - fix perhaps route observable - to detect changes in url and search accordingly could work...
    routeData: {
      validAccountTypes: allTypes,
      minimumPermission: AccountPermission.basic,
      animation: 'scienceMatch',
    },
  },
  calendar: {
    title: hardcodedValues.calendar,
    path: 'calendar',
    route: 'calendar',
    icon: 'calendar_today',
    routeData: {
      validAccountTypes: allTypes,
      minimumPermission: AccountPermission.basic,
      animation: 'calendar',
    },
  },
};

export interface INavigationItem {
  title: string;
  path: string;
  icon: string;
  route: string;
  routeData: IRouteData;
  excludeFromNavBar: boolean;
  external: boolean;
  root: string;
  page: string;
  hint: string;
  showSpacer: boolean;
}

export interface IRouteData {
  validAccountTypes: AccountType[];
  minimumPermission: AccountPermission;
  animation: string;
}
