import { Site } from '../models';

export const isNumber = (a) => typeof a === 'number';
export const isObject = (a) => a instanceof Object;
export const isNil = (val) => {
  return val == null || val == undefined || val == '';
};
export const isEmpty = (obj) =>
  [Object, Array].includes((obj || {}).constructor) &&
  !Object.entries(obj || {}).length;
export const isBoolean = (arg) => arg === !!arg;
export const isString = (str) => {
  if (str != null && typeof str.valueOf() === 'string') {
    return true;
  }
  return false;
};
export const last = (arr) => arr[arr.length - 1]; // double check! used in feed, infinite scroll

// throttle
// find orderBy function
// find escaperegex function
// distinctUntilChanged() in Site.comp.
