import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { acaConfig } from 'aca-config';
import { Group } from 'app/groups/models/group';

@Component({
  selector: 'app-preview-cv',
  template: `
    <mat-card>
      <mat-card-header>
        <div mat-card-avatar class="premium-icon">
          <!-- Add an icon or image for premium content here -->
          <mat-icon>star</mat-icon>
        </div>
        <mat-card-title>{{ data?.name }}</mat-card-title>
        <mat-card-subtitle
          >{{ 'ThisIs' | preventHardcoding }}
          {{ 'premiumCommunity' | preventHardcoding }}, {{ data?.name }}
        </mat-card-subtitle>
      </mat-card-header>
      <img
        style="min-height: 300px;"
        mat-card-image
        src="https://firebasestorage.googleapis.com/v0/b/sustainable-denmark-demo.appspot.com/o/Samlet%20billede.svg?alt=media&token=f911dddc-cb97-437a-8c25-44e61bcb4653"
        alt="Premium Content Image"
      />
      <mat-card-content>
        <!-- <p>
          {{ 'PremiumDesc1' | preventHardcoding }}
        </p> -->
        <p>
          Hej! Opgradér i dag og få adgang til Greenlink Premiums tre netværk:
        </p>
        <ul>
          <li>ESG: Få styr på datakrav og standarder</li>
          <li>Bæredygtig ledelse og innovation: Find vej til ny forretning</li>
          <li>Cirkulær økonomi: Undgå spild, spar penge</li>
        </ul>
        <p>
          Du får adgang til nyeste viden og værktøjer, eksperter og
          begivenheder, så du kan vende bæredygtighed til business i tide.
        </p>
        <p>Få en måned gratis og oplev alle fordelene.</p>
        <p>
          {{ 'YouAreNotPremiumUserYet' | preventHardcoding }}
          <a [href]="createMailtoHref()" target="_blank">
            {{ 'supportMail' | preventHardcoding }}
          </a>
        </p>
      </mat-card-content>
      <mat-card-actions>
        <button mat-raised-button color="primary">
          <mat-icon>email</mat-icon>
          <span>
            &nbsp;&nbsp;<a
              style="color: white; text-decoration: none !important;"
              [href]="createMailtoHref()"
              target="_blank"
            >
              {{ 'contact' | preventHardcoding }}
            </a>
          </span>
        </button>
      </mat-card-actions>
    </mat-card>
  `,
})
export class PreviewPremiumComponent implements OnInit {
  constructor(@Inject(MAT_DIALOG_DATA) public data: { name: string }) {}

  ngOnInit() {}

  createMailtoHref(): string {
    const displayName =
      acaConfig.language === 'da'
        ? `Hej ${acaConfig.academondo},`
        : `Hi ${acaConfig.academondo},`;
    const subject = `${
      acaConfig.language === 'da'
        ? `${acaConfig.academondo} Premium`
        : `${acaConfig.academondo} Premium`
    }`;

    const bodyPartTwo =
      acaConfig.language === 'da'
        ? `Jeg vil gerne høre mere om ${acaConfig.academondo} Premium`
        : `I would like to hear more about ${acaConfig.academondo} Premium`;
    const mailtoUrl = `mailto:${acaConfig.supportMail}
    ?subject=${encodeURI(subject)}
    &body=${encodeURI(displayName)}%0A
    %0A${encodeURI(bodyPartTwo)}
    `;
    return mailtoUrl;
  }
}
