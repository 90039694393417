import { acaConfig } from './aca-config';

const names = {
  auser: 'en profil',
  user: 'profil',
  User: 'Profil',
  users: 'profiler',
  Users: 'Profiler',
  thesite: 'virksomheden',
  asite: 'en virksomhed',
  newsite: 'ny virksomhed',
  Newsite: 'Ny virksomhed',
  companyName: `virksomhedsnavn`,
  CompanyName: `Virksomhedsnavn`,
  site: 'virksomhed',
  Site: 'Virksomhed',
  sites: 'virksomheder',
  Sites: 'Virksomheder',
  thejob: 'opslaget',
  ajob: 'et opslag',
  yourjob: 'dit opslag',
  Yourjob: 'Dit opslag',
  newJobPosition: 'Nyt opslag',
  Oldjob: 'Gammelt opslag',
  job: 'opslag',
  Job: 'Opslag',
  jobs: 'opslag',
  Jobs: 'Opslag',
};

export const acaNamesSUSTAINABLE = {
  ReactionLike: 'thumb_up_off_alt',
  ReactionLikeFull: 'thumb_up',
  ReactionLikeText: 'Synes godt om',
  ReactionFavorite: 'favorite_border',
  ReactionFavoriteFull: 'favorite',
  ReactionFavoriteText: 'Elsker',
  ReactionRecycling: 'recycling',
  ReactionRecyclingFull: 'recycling',
  ReactionRecyclingText: 'Grønt like',
  ReactionDislike: 'thumb_down_off_alt',
  ReactionDislikeFull: 'thumb_down',
  ReactionDislikeText: 'Synes ikke godt om',
  ReactionNeutral: 'sentiment_neutral',
  ReactionNeutralFull: 'sentiment_neutral',
  ReactionNeutralText: 'Neutral',
  ReactionAngry: 'sentiment_very_dissatisfied',
  ReactionAngryFull: 'sentiment_very_dissatisfied',
  ReactionAngryText: '',

  useLanguageForCV: false,
  category0: 'verdensmål',
  Category0: '', // leave blank if it should not be shown
  categories0: 'verdensmålene',
  Categories0: 'Verdensmålene',
  category1: 'Ecorp type',
  Category1: '', // leave blank if it should not be shown
  categories1: 'Ecorp types',
  Categories1: 'Ecorp types',
  category2: 'alternativ region',
  Category2: '', // leave blank if it should not be shown - remember builders - stepindex.ts
  categories2: 'alternativ regioner',
  Categories2: 'Alternativ regioner',
  category3: 'uddannelse',
  Category3: '', // leave blank if it should not be shown - remember builders - stepindex.ts
  categories3: 'uddannelser',
  Categories3: 'Uddannelser',
  showCategories3InCVEducationStep: false,
  category4: '',
  Category4: '', // leave blank if it should not be shown - remember builders - stepindex.ts
  categories4: '',
  Categories4: '',
  category5: '',
  Category5: '', // leave blank if it should not be shown - remember builders - stepindex.ts
  categories5: '',
  Categories5: '',
  Category0Hint: '',
  Category0Link: '',
  Category0Dialog: '',
  Category0Tooltip: '',
  Category1Hint: '',
  Category1Link: '',
  Category1Dialog: '',
  Category1Tooltip: '',
  Category2Hint: '',
  Category2Link: '',
  Category2Dialog: false,
  Category2Tooltip: false,
  Category3Hint: '',
  Category3Link: '',
  Category3Dialog: '',
  Category3Tooltip: '',
  Category4Hint: '',
  Category4Link: '',
  Category4Dialog: '',
  Category4Tooltip: '',
  Category5Hint: '',
  Category5Link: '',
  Category5Dialog: '',
  Category5Tooltip: '',
  FoiHint: '',
  FoiLink: '',
  FoiDialog: '',
  FoiTooltip: '',
  TechHint: '',
  TechLink: '',
  TechDialog: '',
  TechTooltip: '',
  siteInfoStepTitle: `${names.Site} Info`,
  siteInfoDate: '',
  siteInfoDateHint: '',
  siteQuestion0: '',
  siteQuestion0Hint: '',
  siteQuestion0Link: '',
  siteQuestion0Dialog: false,
  siteQuestion0Tooltip: false,
  siteQuestion1: '',
  siteQuestion1Hint: '',
  siteQuestion1Link: '',
  siteQuestion1Dialog: false,
  siteQuestion1Tooltip: false,
  siteQuestion2: '',
  siteQuestion2Hint: '',
  siteQuestion2Link: '',
  siteQuestion2Dialog: false,
  siteQuestion2Tooltip: false,
  siteQuestion3: '',
  siteQuestion3Hint: '',
  siteQuestion3Link: '',
  siteQuestion3Dialog: false,
  siteQuestion3Tooltip: false,
  siteQuestion4: '',
  siteQuestion4Hint: '',
  siteQuestion4Link: '',
  siteQuestion4Dialog: false,
  siteQuestion4Tooltip: false,
  GraduateProgram: '',
  GraduateProgramHint: '',
  GraduateProgramLink: '',
  GraduateProgramDialog: '',
  GraduateProgramTooltip: '',
  AreYouOnDimittendsats: 'Er du på dimittendsats',
  Dimittendsats: '',
  DimittendsatsHint: '',
  DimittendsatsLink: '',
  DimittendsatsDialog: '',
  DimittendsatsTooltip: '',
  Akademikerkampagnen: '',
  AreYouOnAkademikerkampagnen: 'Har du været på kursus hos Akademikerkampagnen',
  AkademikerkampagnenHint: '',
  AkademikerkampagnenLink: '',
  AkademikerkampagnenDialog: '',
  AkademikerkampagnenTooltip: '',

  CPRHint: '',
  CPRLink: '',
  CPRDialog: false,
  CPRTooltip: false,
  experience0: 'academia',
  Experience0: '', // leave blank if it should not be shown
  ExperienceUnit0: ``, // `m<sup>2</sup>`,
  experience1: 'industry',
  Experience1: '', // leave blank if it should not be shown
  ExperienceUnit1: ``, // `m<sup>2</sup>`,
  experience2: 'hospital',
  Experience2: '', // leave blank if it should not be shown
  ExperienceUnit2: ``, // `m<sup>2</sup>`,
  experience3: 'entrepreneurship',
  Experience3: '', // 'Entrepreneurship', // leave blank if it should not be shown
  ExperienceUnit3: ``, // ' vær.',
  experience4: '',
  Experience4: '', // 'Public sector/health', // leave blank if it should not be shown
  ExperienceUnit4: ``, // ' ',
  experience5: '',
  Experience5: '', // leave blank if it should not be shown
  ExperienceUnit5: ``, // 'Mio.kr.',
  Specials: 'Specials',
  UserType0: 'Person', // leave blank if it should not be shown
  UserTypes0: 'Personer',
  UserType1: 'Virksomhed', // leave blank if it should not be shown
  UserTypes1: 'Virksomheder',
  UserType2: '', // leave blank if it should not be shown
  UserTypes2: 'Organisationer',
  UserType3: '', // leave blank if it should not be shown
  UserTypes3: '',
  UserType4: '', // leave blank if it should not be shown
  UserTypes4: 'Biomedical Scientists',
  UserType5: '', // leave blank if it should not be shown
  UserTypes5: 'SSO',
  auser: names.auser,
  user: names.user,
  User: names.User,
  users: names.users,
  Users: names.Users,
  thesite: names.thesite,
  asite: names.asite,
  newsite: names.newsite,
  Newsite: names.Newsite,
  companyName: names.companyName,
  CompanyName: names.CompanyName,
  CompanyNameJob: names.CompanyName,
  site: names.site,
  Site: names.Site,
  sites: names.sites,
  Sites: names.Sites,
  siteWebsiteUrl: 'Hjemmeside url',
  CompanyJobStep: names.Site,
  theplace: 'stedet',
  place: 'sted',
  Place: 'Sted',
  places: 'steder',
  Places: 'Steder',
  thecv: `profilen`,
  CVRoute: 'profil',
  Yourcv: 'Din profil',
  yourcv: 'din profil',
  CV: 'Profil',
  cv: 'profil',
  CVs: 'Profiler',
  cvs: 'profiler',

  subTitle: '',
  academicTitle: 'Stillingsbetegnelse',

  JobType0: 'Rest/Overskudsprodukter', // leave blank if it should not be shown
  JobType1: 'Opgave/Behov', // leave blank if it should not be shown
  JobType2: 'Produkt/Ydelse', // leave blank if it should not be shown
  JobType3: '', // 'Entrepreneurship', // leave blank if it should not be shown
  JobType4: '', // 'Public sector/health', // leave blank if it should not be shown
  JobType5: '', // leave blank if it should not be shown
  JobType6: '', // leave blank if it should not be shown
  JobType7: '', // leave blank if it should not be shown
  JobType8: '', // leave blank if it should not be shown
  JobType9: '', // leave blank if it should not be shown

  jobType0Desc: '', // leave blank if it should not be shown
  jobType1Desc: '', // leave blank if it should not be shown
  jobType2Desc: '', // leave blank if it should not be shown
  jobType3Desc: '', // leave blank if it should not be shown
  jobType4Desc: '', // leave blank if it should not be shown
  jobType5Desc: '', // leave blank if it should not be shown
  jobType6Desc: '', // leave blank if it should not be shown
  jobType7Desc: '', // leave blank if it should not be shown
  jobType8Desc: '', // leave blank if it should not be shown
  jobType9Desc: '', // leave blank if it should not be shown
  thejob: names.thejob,
  ajob: names.ajob,
  yourjob: names.yourjob,
  Yourjob: names.Yourjob,
  newJobPosition: names.newJobPosition,
  Oldjob: names.Oldjob,
  job: names.job,
  Job: names.Job,
  jobs: names.jobs,
  Jobs: names.Jobs,
  JobType: 'Type',

  shortFoi: 'branche',
  ShortFoi: 'Branche',
  foi: 'branche',
  Foi: 'Branche', // leave blank if it should not be shown
  fois: 'branche',
  Fois: 'Brancher',
  tech: 'ydelse',
  Tech: 'Ydelse', // leave blank if it should not be shown
  techs: 'ydelser',
  Techs: 'Ydelser',
  companySubtype: 'type',
  CompanySubtype: '', // leave blank if it should not be shown
  companySubtypes: 'typer',
  CompanySubtypes: 'Typer',

  ApplyCommunity: `Ansøg`,
  ApplyEvent: `Ansøg`,
  ApplyIdea: `Ansøg`,
  becomeMemberCommunity: `Følg`,
  becomeMemberEvent: `Bliv medlem`,
  becomeMemberIdea: `Bliv medlem`,

  becomeMemberCommunityTooltip: `Du vil få notifikationer på nye indlæg`,
  becomeMemberEventTooltip: ``,
  becomeMemberIdeaTooltip: ``,
  thenetwork: 'netværket',
  network: 'netværk',
  Network: 'Netværk',
  networks: 'netværk',
  Networks: 'Netværk',
  community: 'netværk',
  Community: 'Netværk',
  communities: 'netværk',
  Communities: 'Netværk',
  YourCommunities: 'Dine netværk',
  communitiesTitle: 'Netværk',
  initialCommunityName: `Nyt netværk`,
  YourPremiumCommunities: 'Greenlink Premium',
  premiumCommunity: 'Greenlink Premium',
  PremiumCommunity: 'Greenlink Premium',
  premiumCommunities: 'Greenlink Premium',
  PremiumCommunities: 'Greenlink Premium',
  yourNewSubgroup: 'dit nye undernetværk',
  newSubgroup: 'nyt undernetværk',
  subgroup: 'undernetværk',
  Subgroup: 'Undernetværk',
  subgroups: 'undernetværk',
  Subgroups: 'Undernetværk',
  theidea: 'projektet',
  idea: 'projekt',
  Idea: '', // leave blank if it should not be shown
  ideas: 'projekter',
  Ideas: 'Projekter',
  YourIdeas: 'Dine projekter',
  yourIdea: 'dit projekt',
  initialIdeaName: `Nyt projekt`,
  ideaName: `projekt navn`,
  IdeaName: `Projekt navn`,
  IdeasIcon: 'lightbulb',
  IdeasTitle: 'Projekter',
  ideaStartStep1: `Velkommen til projektbyggeren, følg disse steps for at oprette dit eget projekt.`,
  ideaStartStep2: `Et projekt kan f.eks være noget, du ønsker input til eller noget du ønsker at samarbejde med andre om`,
  showAdressSelectorInIdeas: true,
  showWebsiteInIdeas: true,
  websiteCollaboratoryUrl: '',
  theevent: 'begivenheden',
  Theevent: 'Begivenheden',
  thisevent: 'denne begivenhed',
  Thisevent: 'Denne begivenhed',
  newEvent: 'ny begivenhed',
  eventName: 'begivenhedsnavn',
  EventName: 'Begivenhedsnavn',
  event: 'begivenhed',
  Event: 'Begivenhed',
  events: 'begivenheder',
  Events: 'Begivenheder',
  YourEvents: 'Dine begivenheder',
  yourEvent: 'din begivenhed',
  EventsTitle: 'Begivenheder',
  Moderator: 'Moderator',
  Manager: 'Manager',
  member: 'medlem',
  Member: 'Medlem',
  members: 'medlemmer',
  Members: 'Medlemmer',
  participant: 'deltager',
  Participant: 'Deltager',
  participants: 'deltagere',
  Participants: 'Deltagere',
  participantInvite: 'inviteret',
  ParticipantInvite: 'Inviteret',
  participantsInvite: 'deltagere',
  ParticipantsInvite: 'Deltagere',

  Public: 'Offentlig',
  OpenPrivate: 'Åbent',
  Private: 'Privat',
  Hidden: 'Skjult',
  InviteOnly: 'Hemmeligt',
  public: 'offentlig',
  openPrivate: 'åbent',
  private: 'privat',
  inviteOnly: 'hemmeligt',
  hidden: 'skjult',

  overwriteJobsButtonInSearch: 'Børs',
  overwritePostsButtonInSearch: 'Nyeste viden',
  notEnoughPermission: `Velkommen til ${acaConfig.academondo}.
  Opret din profil for at få adgang`,
  dashboardWelcomeTitle: `ESPORT DANMARK - FOR GAMERNE`,
  dashboardWelcomeSubTitle: ``,
  scienceMatchRoute: 'søgning',
  scienceMatch: 'Søgning',
  scienceMatchSubtitle: 'Sammen gør vi verden grønnere',
  accountSelector1: `Hvem er ${acaConfig.academondo} lavet til?`, // `Who is ${acaConfig.academondo} for?`
  accountSelector2: 'Alle i dansk erhvervsliv', // 'Everybody with a passion within Life Science, Health- or Foodtech',
  accountSelector3: 'Jeg er en person', // `I'm an individual`
  accountSelector4: 'Jeg er en virksomhed', // `I'm a company, university, organisation or hospital`
  inviteToAcademondoText: `Invitér dine kontakter til ${acaConfig.academondo}`,

  showOrcId: false,
  showGithub: false,
  showTwitter: false,
  thisWillMakeCVSearchable: 'Dette gør din profil synlig.',
  showTitleadditionalInformation: false,
  additionalInformation: 'Supplerende information',
  additionalInformationEtc:
    'Her kan du indsætte andre ting som du mener hører til i din profil',
  writeTheRelevantInformation: 'Skriv relevant information',
  writeInfoAboutCompany: `Beskriv ${names.thesite}. (prøv copy/paste fra eks. word) 😉`, // `Beskriv ${acaNames.site}en.`,
  writeQualificationsPosition: 'Beskriv kategorierne.', // 'Beskriv kategorierne.',
  permanentContract: 'Fast kontrakt',
  dateOfBirthHint: '', // 'This will not be published - only for statistics',
  // intro
  showTitleintroductoryText: false,
  introductoryText: 'Introduktionstekst',
  writeAboutYourself: 'Skriv om dig selv, din karriere og dit fokusområde',
  titleOfEducationHint: 'eks. Molecular Nanotechnology',

  dashboardRoute: 'start-side',
  dashboardTitle: 'Startside',
  jobBuilderRoute: 'opslag',
  jobBuilderTitle: 'Opret børsopslag',
  siteBuilderRoute: names.Sites,

  Basic: 'Basis',
  Extended: 'Betalt',
  Full: 'Greenlink Premium',
  Admin: 'Admin',

  placesUrl2: '',
  PlacesTypes0: 'Steder',
  PlacesTypes1: 'Hub',
  PlacesTypes2: '',
  PlacesTypes3: '',

  signUp: `Opret din profil`,

  // intro dialog - cv
  helpheaderCV: 'Du er nu klar til at bygge din profil!',
  helpLineOneCV:
    'Denne interaktive profilbygger tillader dig at se din profil, mens du bygger den.',
  helpLineTwop1CV: 'Husk at trykke',
  helpLineTwop2CV: 'for at gøre det',
  helpLineTwop3CV: `synligt på ${acaConfig.academondo}`,
  helpLineThreep1CV: '',
  helpLineThreep2CV: '',

  // intro dialog - job
  helpheaderJob:
    'Du er nu klar til at lave annoncer! Denne interaktive feature tilader dig at se dit opslag mens du laver det. Når du er klar, så starter du bare og se din annonce tage form mens du skriver.',
  helpLineOneJob:
    'Når du er færdig, så husk at trykke "gem" for at gøre annoncen synlig for alle på platformen',
  helpLineTwoJob:
    'Igen, Velkommen til, Vi er glade for at kunne hjælpe dig på vej mod at finde de bedste samarbejdspartnere!',
  jobStartStep1: `Velkommen til ${names.job}byggeren, her kan du oprette ${names.jobs}.`,
  jobStartStep2: `Husk at offentliggøre ${names.yourjob} ved at trykke på "Offentliggør" til sidst`,
  beginBuildingYourJobPost: `Byg ${names.yourjob}`,
  toPublish: 'offentliggøre',
  publish: 'offentliggør',
  Publish: 'Offentliggør',
  published: 'offentliggjort',
  Published: 'Offentliggjort',
  publishedPlural: 'offentliggjorte',
  publishing: 'offentliggørelse',
  Publishing: 'Offentliggørelse',
  PublishJob: `Offentliggør ${names.job}`,
  PublishJobs: `Offentliggør ${names.jobs}`,
  publishMenu: 'Tryk her',
  employmentPeriod: 'Ansættelsesperiode',

  PlanSelectorTitle: 'Vælg pakke',
  PlanSelectorSubTitle: 'tilbyder pakker til alle - hvilken passer dig?',
  PlanSelectorParagraph1: 'Her er hvad vi tilbyder:',
  PlanSelectorListItem1:
    'Unik eksponering: Find potentielle kunder, investorer eller samarbejdspartnere',
  PlanSelectorListItem2:
    'Få ubegrænset adgang til at oprette tilbud, stillingsopslag eller projekter. Deltage i netværk for at samarbejde, innovere og dele viden',
  PlanSelectorListItem3:
    'Det er gratis for medlemmer hos vores partnere: Ringkøbing-Skjern Erhversråd, Business Viborg',
  PlanSelectorListItem4: 'Kontakt Thomas Nørhaven på 21426444',
  // map
  mapSiteTooltip: '',
  mapSiteHint: '',
};
