import { Component, Input, OnInit } from '@angular/core';
import { StatisticService } from 'app/core/statistic.service';
import { GroupType } from 'app/groups/models/group-type';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-user-statistics',
  templateUrl: './user-statistics.component.html',
  styleUrls: ['./user-statistics.component.scss'],
})
export class UserStatisticsComponent implements OnInit {
  @Input() userId: string;
  getNumberOfUserPostsById$;
  getNumberOfUserCommunitiesMembershipsById$;
  getNumberOfUserEventsMembershipsById$;
  getNumberOfUserIdeasMembershipsById$;

  numberOfPosts: number;

  numberOfOwnerCommunities: number;
  numberOfMemberCommunities: number;

  numberOfOwnerEvents: number;
  numberOfMemberEvents: number;

  numberOfOwnerIdeas: number;
  numberOfMemberIdeas: number;
  constructor(private statisticService: StatisticService) {}

  ngOnInit() {
    this.getNumberOfUserPostsById$ = this.statisticService
      .getNumberOfUserPostsById$(this.userId)
      .pipe(
        tap((entities) => {
          this.numberOfPosts = entities;
        })
      );

    this.getNumberOfUserCommunitiesMembershipsById$ = this.statisticService
      .getNumberOfUserMembershipsByIdAndType$(
        this.userId,
        GroupType.Communities
      )
      .pipe(
        tap((entities: string[]) => {
          this.numberOfOwnerCommunities = entities
            .filter((entity) => {
              if (
                entity === 'Owner' ||
                entity === 'Administrator' ||
                entity === 'Moderator' ||
                entity === 'Member'
              ) {
                return entity;
              }
              return null;
            })
            .reduce((acc) => {
              return acc + 1;
            }, 0);
        })
        // tap((entities: string[]) => {
        //   this.numberOfMemberCommunities = entities
        //     .filter((entity) => {
        //       if (entity === 'Member') {
        //         return entity;
        //       }
        //       return null;
        //     })
        //     .reduce((acc) => {
        //       return acc + 1;
        //     }, 0);
        // })
      );
    this.getNumberOfUserEventsMembershipsById$ = this.statisticService
      .getNumberOfUserMembershipsByIdAndType$(this.userId, GroupType.Events)
      .pipe(
        tap((entities: string[]) => {
          this.numberOfOwnerEvents = entities
            .filter((entity) => {
              if (
                entity === 'Owner' ||
                entity === 'Administrator' ||
                entity === 'Moderator' ||
                entity === 'Member'
              ) {
                return entity;
              }
              return null;
            })
            .reduce((acc) => {
              return acc + 1;
            }, 0);
        })
        // tap((entities: string[]) => {
        //   this.numberOfMemberEvents = entities
        //     .filter((entity) => {
        //       if (entity === 'Member') {
        //         return entity;
        //       }
        //       return null;
        //     })
        //     .reduce((acc) => {
        //       return acc + 1;
        //     }, 0);
        // })
      );

    this.getNumberOfUserIdeasMembershipsById$ = this.statisticService
      .getNumberOfUserMembershipsByIdAndType$(this.userId, GroupType.Ideas)
      .pipe(
        tap((entities: string[]) => {
          this.numberOfOwnerIdeas = entities
            .filter((entity) => {
              if (
                entity === 'Owner' ||
                entity === 'Administrator' ||
                entity === 'Moderator' ||
                entity === 'Member'
              ) {
                return entity;
              }
              return null;
            })
            .reduce((acc) => {
              return acc + 1;
            }, 0);
        })
        // tap((entities: string[]) => {
        //   this.numberOfMemberIdeas = entities
        //     .filter((entity) => {
        //       if (entity === 'Member') {
        //         return entity;
        //       }
        //       return null;
        //     })
        //     .reduce((acc) => {
        //       return acc + 1;
        //     }, 0);
        // })
      );
  }
}
