import {
  Component,
  OnInit,
  Input,
  ChangeDetectionStrategy,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-youtube-video',
  templateUrl: './youtube-video.component.html',
  styleUrls: ['./youtube-video.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class YouTubeVideoComponent implements OnInit {
  @Input() link: string;
  constructor(private _domSanitizer: DomSanitizer) {}

  get id() {
    if (this.link && this.link.length === 11) {
      return this._domSanitizer.bypassSecurityTrustResourceUrl(
        'https://www.youtube-nocookie.com/embed/' + this.link
      );
    }
    const split = this.link ? this.link.split('v=')[1] : null;
    if (split) {
      const videoId = split.substring(0, 11);
      if (videoId.length === 11) {
        return this._domSanitizer.bypassSecurityTrustResourceUrl(
          'https://www.youtube-nocookie.com/embed/' + videoId
        );
      }
    }

    const idFromshareLink = this.getYouTubeId(this.link)
      ? this.getYouTubeId(this.link)
      : null;
    if (idFromshareLink) {
      return this._domSanitizer.bypassSecurityTrustResourceUrl(
        'https://www.youtube-nocookie.com/embed/' + idFromshareLink
      );
    }

    return null;
  }

  ngOnInit() {}

  getYouTubeId(link: string): string {
    const url = link.split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
    return url[2] !== undefined ? url[2].split(/[^0-9a-z_\-]/i)[0] : url[0];
  }
}
